<template>

    <div v-if="unit">

        <div class="unit gr-24">

            <h3 class="unit-title gr-24">{{ unit.title }}</h3>

            <p class="unit-introduction gr-24" v-if="unit.introduction">{{ unit.introduction }}</p>

            <div class="unit-video gr-16 gr-22@mobile" v-if="unit.video">
                <vimeo-player :ref="unit.slug" :video-id="unit.video"></vimeo-player>
            </div>
          <div class="gr-8 gr-16@tablet mt-1">

            <a v-if="videoMapping[module.slug].units[unit.slug] > 1"
               @click="switchUnit(videoMapping[module.slug].units[unit.slug] - 1)">
              <base-button class="button mb-1 mr-05">Zurück</base-button>
            </a>

            <a v-else-if="videoMapping[module.slug].number > 1"
               @click="switchModule(videoMapping[module.slug].number - 1, 'back')">
              <base-button class="button mb-1 mr-05">Zurück zu Kapitel {{ videoMapping[module.slug].number - 1 }}</base-button>
            </a>

            <a v-if="videoMapping[module.slug].units[unit.slug] < Object.keys(videoMapping[module.slug].units).length"
               @click="switchUnit(videoMapping[module.slug].units[unit.slug] + 1)">
              <base-button class="button mb-1">Weiter</base-button>
            </a>

            <a v-else-if="videoMapping[module.slug].number < Object.keys(videoMapping).length"
               @click="switchModule(videoMapping[module.slug].number + 1, 'next')">
              <base-button class="button mb-1">Weiter zu Kapitel {{ videoMapping[module.slug].number + 1 }}</base-button>
            </a>

            <base-button @click="toggleCompletionState(!completed[unit], unit)"
                         :color="user.completed && user.completed[training.slug] && user.completed[training.slug][module.slug]
                         && user.completed[training.slug][module.slug][unit.slug]? 'green' : 'orange'" class="button">
                {{ user.completed && user.completed[training.slug] && user.completed[training.slug][module.slug] &&
                user.completed[training.slug][module.slug][unit.slug] === true? 'abgeschlossen' : 'als abgeschlossen markieren' }}
            </base-button>
          </div>

            <div>{{ completed[unit] }}</div>

        </div>

    </div>

</template>

<script>
    import BaseButton from "@/components/ui/BaseButton";
    export default {
      components: {BaseButton},
      props: ['unitSlug', 'module', 'training', 'authData', 'user'],
        emits: ['setActiveUnit'],
        inject: ['switchModule', 'setCompletionState'],
        data() {
            return {
                completed: {},
            }
        },
        watch: {
            user(curVal, oldVal) {
                if(curVal.completed !== oldVal.completed) {
                    this.completed[this.unit.slug] = curVal.completed && curVal.completed[this.training.slug] &&
                    curVal.completed[this.training.slug][this.module.slug]?
                        curVal.completed[this.training.slug][this.module.slug][this.unit.slug] : false;
                }
            },
            $route(curVal, oldVal) {
                if(curVal.params.unitSlug && curVal.params.unitSlug !== oldVal.params.unitSlug) {
                    this.saveLastPosition(curVal.params.moduleSlug, curVal.params.unitSlug);
                    this.$emit('setActiveUnit', curVal.params.unitSlug);
                }
            }
        },
        mounted() {
            this.completed[this.unit.slug] = this.user.completed && this.user.completed[this.training.slug] &&
            this.user.completed[this.training.slug][this.module.slug]?
                this.user.completed[this.training.slug][this.module.slug][this.unit.slug] : false;

            this.saveLastPosition(this.module.slug, this.unit.slug);
            this.$emit('setActiveUnit', this.unit.slug);
        },
        methods: {
            async saveLastPosition(moduleSlug, unitSlug) {
                let unitTitle = false;
                for(let key in this.module.units) {
                    let unit = this.module.units[key];
                    if(unit.slug === unitSlug) {
                        unitTitle = unit.title;
                    }
                }

                let position = this.user.lastPosition || {};

                position[this.training.slug] = {
                    module: {
                        slug: moduleSlug,
                        title: this.module.title,
                        unit: {
                            slug: unitSlug,
                            title: unitTitle
                        }

                    },
                };

                const savePosition = await this.$store.dispatch('users/saveLastPosition', {
                    jwt: this.authData.jwt,
                    position: position,
                })

                if(await savePosition) {
                    this.fetchUser();
                }
            },
            switchUnit(number) {
                let unitSlug;

                for(let [slug, num] of Object.entries(this.videoMapping[this.module.slug].units)) {
                    if(num === number) {
                        unitSlug = slug;
                    }
                }

                this.$router.push({ name: 'unit', params: { 'unitSlug': unitSlug } });
            },
            async switchModule(number, direction) {
                let moduleSlug;
                let unitSlug;

                for(let [mSlug, module] of Object.entries(this.videoMapping)) {
                    if(module.number === number) {
                        moduleSlug = mSlug;

                        for(let [uSlug, num] of Object.entries(this.videoMapping[moduleSlug].units)) {
                            if(direction === 'back') {
                                if(num === Object.keys(this.videoMapping[moduleSlug].units).length) {
                                    unitSlug = uSlug;
                                }
                            } else {
                                if(num === 1) {
                                    unitSlug = uSlug;
                                }
                            }
                        }
                    }
                }

                this.$router.push({ name: 'unit', params: { 'moduleSlug': moduleSlug, 'unitSlug': unitSlug } });
            },
            toggleCompletionState() {
                let completed = true;

                if(this.user.completed && this.user.completed[this.training.slug] && this.user.completed[this.training.slug][this.module.slug] && this.user.completed[this.training.slug][this.module.slug][this.unit.slug]) {
                    completed = false;
                }

                this.setCompletionState(completed, this.unit);
            },
        },
        computed: {
            units() {
                return this.module.units;
            },
            unit() {
                let unit;

                for(let key in this.units) {
                    let targetUnit = this.units[key];
                    if(targetUnit.slug === this.unitSlug) {
                        unit = targetUnit;
                    }
                }

                return unit;
            },
            videoMapping() {
                let mapVideos = {};

                //sort by integer "number" (module + unit field)
                for(let i in this.training.modules) {
                    let module = this.training.modules[i];
                    mapVideos[module.slug] = {};
                    mapVideos[module.slug].number = parseInt(i) + 1;
                    mapVideos[module.slug].units = {};
                    for(let j in module.units) {
                        let unit = module.units[j];
                        mapVideos[module.slug].units[unit.slug] = parseInt(j) + 1;
                    }
                }

                return mapVideos;
            },
        }
    }
</script>


<style scoped>
    /*.vp-target.hidden {
        background-color: black;
    }
    iframe {
        background-color: #000000;
    }*/

    .unit-video {
        /*width: 85%;*/
        margin: 0 auto;
    }
</style>
