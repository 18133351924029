<template>
    <footer>
        <div class="row">
            <div class="gr-24">
                <div class="footer">
                    <div class="gr-16 gr-14@small gr-11@tablet gr-8@mobile push-4 push-6@small push-5@tablet push-0@mobile">
                        <p class="footer-contactlink">
                            <a href="mailto:hallo@starkes-mindset.com">Kontakt</a>
                        </p>
                    </div>
                    <div class="gr-3 gr-4@small gr-5@tablet gr-8@mobile push-1 push-1@tablet push-0@mobile">
                        <p class="footer-datenschutz-link">
                            <router-link :to="{ name: 'privacy' }">Datenschutz</router-link>
                        </p>
                    </div>
                    <div class="gr-3 gr-4@small gr-4@tablet gr-8@mobile push-1 push-2@tablet push-0@mobile">
                        <p class="footer-impressum-link">
                            <router-link :to="{ name: 'imprint' }">Impressum</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {}
</script>

<style scoped>
</style>
