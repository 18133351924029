import { createRouter, createWebHistory } from 'vue-router';

//import store from './store/index.js';

import LandingPage from './pages/LandingPage.vue';
import OrderPage from './pages/OrderPage.vue';
import PortalPage from './pages/PortalPage.vue';
import PortalOverview from './components/portal/PortalOverview.vue';
import PurchasePage from './components/portal/PurchasePage.vue';
import Module from './components/portal/Module.vue';
import Unit from './components/portal/Unit.vue';
import CoachPage from './pages/CoachPage.vue';
import PrivacyPage from './pages/PrivacyPage.vue';
import ImprintPage from './pages/ImprintPage.vue';
import ThankYouPage from './pages/ThankYouPage.vue';
import ResetPasswordPage from './pages/ResetPasswordPage.vue';
//import NotFoundPage from './pages/NotFoundPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'home', component: LandingPage },
        { path: '/:productType/:slug', name: 'course', component: OrderPage, props: true },
        { path: '/portal', name: 'portal',/* redirect: '/portal/purchases',*/ component: PortalPage, meta: { requiresAuth: true }, children: [
            /*{ path: 'purchases', name: 'portalOverview', component: PortalOverview, meta: { requiresAuth: true } },*/
            { path: '', name: 'portalOverview', component: PortalOverview, meta: { requiresAuth: true } },
            { path: 'purchase/:purchaseSlug', name: 'purchase', component: PurchasePage, props: true, meta: { requiresAuth: true }, children: [
                { path: 'chapter/:moduleSlug', name: 'module', component: Module, props: true, meta: { requiresAuth: true }, children: [
                    { path: 'unit/:unitSlug', name: 'unit', component: Unit, props: true, meta: { requiresAuth: true } }
                ]},
            ]},
        ]},
        { path: '/coach/:coachSlug', name: 'coach', component: CoachPage, props: true },
        { path: '/privacy', name: 'privacy', component: PrivacyPage },
        { path: '/imprint', name: 'imprint', component: ImprintPage },
        { path: '/thank-you', name: 'thankYou', component: ThankYouPage },
        { path: '/reset-password', name: 'resetPassword', component: ResetPasswordPage },
        { path: '/:notFound(.*)', name: 'notFound', component: LandingPage }
        /*{ path: '/:notFound(.*)', name: 'notFound', component: NotFoundPage }*/
    ],
    scrollBehavior(to, from, savedPosition) {
        if(savedPosition) {
            return savedPosition;
        }
        return {
            top: 0,
            left: 0,
        }
    }
})

/*router.beforeEach((to, from, next) => {
    if(to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
        next({name: 'home'});
    } else {
        next();
    }
})*/

/*router.beforeEach(async (to, from, next) => {
    let authCheck = await JSON.parse(localStorage.getItem('authData'));

    if(to.meta.requiresAuth && !authCheck) {
        next({name: 'home'});
    } else {
        next();
    }
})*/

export default router;
