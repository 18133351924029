<template>

    <div class="imprint">
        <div class="row">
            <h1 class="push-2 gr-24">Impressum
            </h1>
        </div>

        <div class="row">
            <div class="gr-24">
                <div class="text gr-20 push-2">
                    <p>Peter Stark KG<br/>
                        Gartenweg 12<br/>
                        A-9210 Pörtschach</p>

                    <h3>Vertreten durch:</h3>
                    <p>Mag. Peter Stark</p>

                    <h3>Kontakt:</h3>
                    <p>E-Mail: <a href="mailto:hallo@starkes-mindset.com">hallo@starkes-mindset.com</a></p>

                    <h3>Registereintrag:</h3>
                    <p>Eintragung im Handelsregister</p>
                    <p>Registergericht: Landesgericht Klagenfurt</p>
                    <p>Registernummer: FN 459707 s</p>

                    <h3>Umsatzsteuer:</h3>
                    <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
                        ATU71421936</p>

                    <h3>Berufsbezeichnung und berufsrechtliche Regelungen</h3>
                    <p>Berufsbezeichnung: Unternehmensberatung</p>
                    <p>Zuständige Kammer: Fachgruppe Unternehmensberatung, Buchhaltung und
                        Informationstechnik der Wirtschaftskammer Kärnten</p>
                    <p>Verliehen durch: Österreich</p>
                    <p>Es gelten folgende berufsrechtliche Regelungen: Gewerberechtliche Vorschriften</p>
                    <p>Regelungen einsehbar unter:
                        <u><a href="https://www.ris.bka.gv.at" target="blank">https://www.ris.bka.gv.at</a></u>
                    </p>

                    <h3>Streitschlichtung</h3>
                    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
                        (OS) bereit:
                        <u><a href="https://ec.europa.eu/consumers/odr" target="blank">
                            https://ec.europa.eu/consumers/odr</a></u>.
                    </p>
                    <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
                        einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <h3>Haftung für Inhalte</h3>
                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
                        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
                        10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
                        oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                        zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                    <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
                        nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
                        Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                        werden wir diese Inhalte umgehend entfernen.</p>

                    <h3>Haftung für Links</h3>
                    <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
                        wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
                        keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
                        der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                        verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                        Verlinkung nicht erkennbar.</p>
                    <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
                        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                        entfernen.</p>

                    <h3>Urheberrecht</h3>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                        unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
                        Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
                        bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                        Gebrauch gestattet.</p>
                    <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                        umgehend entfernen.</p>

                    <h3>Design und Umsetzung</h3>
                    <p><u><a href="https://ions.at" target="_blank">IONS.at</a></u></p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {}
</script>

<style scoped>
</style>
