import axios from 'axios';
import { REST_API_URL } from '../../../data/links.js';

export default {
    namespaced: true,
    state() {
        return {
            products: null,
            product: null,
            courseContent: null,
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products;
        },
        setProduct(state, product) {
            state.product = product;
        },
        setCourseContent(state, data) {
            state.courseContent = data;
        },
    },
    actions: {
        async fetchProducts({dispatch, commit}) {
            let products = [];

            const response =  await axios.get(REST_API_URL + '/products?populate=coaches,members,image');
            const productsData = await response.data.data;

            if (response.ok == false) {
                const log = productsData.message || 'Error: Produkte konnten nicht abgerufen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            for(let key in productsData) {
                let id = productsData[key].id;
                let product = productsData[key].attributes;
                product['id'] = id;
                products.push(product);
            }

            commit('setProducts', products);
        },
        async fetchCourseContent({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const slug = payload.slug;
            const type = payload.productType;

            let key = false;

            const keys = await axios.post(REST_API_URL + '/users-permissions/me/product-keys',
                {
                    apiKey: process.env.VUE_APP_STRAPI_API_KEY
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json',
                    }
                },
            );

            const keysData = await keys.data;

            if(keys.ok == false) {
                const log = keysData.message || 'Error: Produktschlüssel des Users konnten nicht abgerufen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            for(let [productSlug, productKey] of Object.entries(keysData)) {
                if(productSlug === slug) {
                    key = productKey;
                }
            }

            // protect route
            if(!key) {
                return false;
            }

            const response = await axios.post(REST_API_URL + '/products/' + slug + '/content',
                {
                    key: key,
                    type: type
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                        'Content-Type': 'application/json'
                    }
                }
            )

            const data = await response.data;

            if(response.ok == false) {
                const log = data.message || 'Error: Kursinhalt konnte nicht über Produktschlüssel bezogen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setCourseContent', data);
        },
        async fetchOrderData({dispatch, commit}, payload) {
            const location = payload.location;
            const purchaseId = payload.purchaseId;
            const products = payload.products;
            let data = new FormData();

            data['apiKey'] = process.env.VUE_APP_DIGISTORE_API_KEY;
            data['purchaseId'] = purchaseId;

            const response = await axios.post(location + '/php/sendDigistoreRequest.php', {
                data
            });

            const responseData = await response.data;

            if (responseData.result === 'error') {
                const log = responseData.message || 'Error: Bestelldaten konnten nicht über Digistore abgerufen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            let productId = responseData.data.items[0]['product_id'];
            let product = false;

            product = products.filter(product => product['product_id'] === productId)[0];

            let buyer = responseData.data['buyer'];
            let transactionId = responseData.data['transaction_list'][0].id;

            let orderData = {
                buyer: buyer,
                transactionId: transactionId

            };

            commit('setProduct', product);

            return orderData;
        },
        eraseOrderData({commit}) {
            commit('setProduct', null);
        },
        async handlePayment({dispatch}, payload) {
            const product = payload.product['product_id'];
            const price = payload.product.price;
            const user = payload.user;
            const freebie = payload.freebie;
            const orderIds = payload.orderIds || false;

            const response = await axios.post(REST_API_URL + '/payments',
                {
                    data: {
                        name: user.firstName + ' ' + user.lastName,
                        email: user.email,
                        product: product,
                        price: price,
                        paid: !freebie? price : '0',
                        orderId: orderIds['order'] || '',
                        transactionId: orderIds['transaction'] || '',
                    }
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                        'Content-Type': 'application/json'
                    }
                }
            );

            const responseData = await response.data;

            if(response.ok == false) {
                const log = responseData.message || 'Error: Zahlung konnte nicht in Datenbank aufgenommen werden.';
                dispatch('createLog', log, { root : true });
            }

            return;
        },
    },
    getters: {
        getProducts(state) {
            return state.products;
        },
        getProduct(state) {
            return state.product;
        },
        getCourseContent(state) {
            return state.courseContent;
        }
    }
}
