import axios from 'axios';
import { REST_API_URL } from '../../../data/links.js';

export default {
    namespaced: true,
    state() {
        return {
            user: null,
            // contacts: {},
            contact: null,
            contactPurchases: null,
            coaches: {}
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        /*setContacts(state, contacts) {
            state.contacts = contacts;
        },*/
        setContact(state, contact) {
            state.contact = contact;
        },
        setContactPurchases(state, purchases) {
            state.contactPurchases = purchases;
        },
        setCoaches(state, coaches) {
            state.coaches = coaches;
        }
    },
    actions: {
        async fetchUser({dispatch, commit}, payload) {
            const JWT = payload.jwt;

            try {
                const response = await axios.get(REST_API_URL + '/users/me?populate=contact', {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json',
                    },
                });

                const user = await response.data;

                if(response.ok == false) {
                    const log = user.message || 'Error: User konnte nicht gefunden werden.';
                    dispatch('createLog', log, { root : true });
                    return;
                }

                commit('setUser', user);

                return true;

            } catch(err) {
                return false;
            }
        },
        async createContact({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const userId = payload.userId;
            const data = payload.additionalData;

            const createContact = await axios.post(REST_API_URL + '/contacts?populate=courses,purchases,profile',
                {
                    data: {
                        userId: userId,
                        gender: data.gender || null,
                        firstName: data.firstName || null,
                        lastName: data.lastName || null,
                    }
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json'
                    }
                }
            );

            let contactData = await createContact.data;

            if(createContact.ok == false) {
                const log = contactData.message || 'Error: Kontakt zu vorhandenem User konnte nicht erstellt werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            let contact = contactData.data.attributes;
            contact['id'] = contactData.data.id;

            const createUserRelation = await axios.put(REST_API_URL + '/users-permissions/me?populate=contact',
                {
                    contact: contact.id
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json',
                    }
                }
            );

            const user = await createUserRelation.data;

            if(user.ok == false) {
                const log = createUserRelation.message || 'Error: Produktschlüssel konnte nicht zu Userdaten hinzugefügt werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setContact', contact);
            commit('setUser', user.data);
        },
        /* For platform: to visit profile of other users
        async fetchContacts({commit}) {
            let contacts = [];

            const response = await axios.get(REST_API_URL + '/contacts?populate=user,courses,purchases,profile');
            const contactsData = await response.data.data;

            if(response.ok == false) {
                const error = new Error(contactsData.message || 'Kontakte konnten nicht aufgerufen werden.');
                throw error;
            }

            for(let key in contactsData) {
                let id = contactsData[key].id;
                let contact = contactsData[key].attributes;
                contact['id'] = id;
                contacts.push(contact);

            }

            commit('setCoaches', contacts);
        },*/
        async fetchContact({dispatch, commit}, payload) {
            const userId = payload.userId;

            let contact = await axios.get(REST_API_URL + '/contacts?filters[userId][$eq]=' + userId + '&populate=user');

            const contactData = await contact.data;

            if(contact.ok == false) {
                const log = contactData.message || 'Error: Kontakt konnte nicht über Userdaten bezogen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            /*if(user) {
                contact = user.contact.data.attributes;
                contact['id'] = user.contact.data.id;

                // performance issues strapi: user-permissions don't display relations, even with populate
            }*/

            contact = contactData.data.length? contactData.data[0].attributes : false;

            if(contact) {
                contact['id'] = contactData.data[0].id;
            }

            commit('setContact', contact);
        },
        async fetchContactPurchases({dispatch, commit}, payload) {
            let contactId = payload.contactId;

            const purchases = await axios.get(REST_API_URL + '/contacts/' + contactId + '/purchases', {
                headers: {
                    'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                }
            });

            const purchaseData = await purchases.data;

            if(purchases.ok == false) {
                const log = purchaseData.message || 'Error: Käufe des Kontakts konnten nicht bezogen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            if(purchaseData.length) {
                commit('setContactPurchases', purchaseData);
            } else {
                commit('setContactPurchases', null);
            }
        },
        async fetchCoaches({dispatch, commit}) {
            let coaches = [];

            const response = await axios.get(REST_API_URL + '/contacts?filters[coach][$eq]=true&populate=courses,profile');
            const coachesData = await response.data;

            if(response.ok == false) {
                const log = coachesData.message || 'Error: Coaches konnten nicht aufgerufen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            for(let key in coachesData.data) {
                let coachData = coachesData.data[key];
                let id = coachData.id;
                let coach = coachData.attributes;
                coach['id'] = id;
                coaches.push(coach);
            }

            commit('setCoaches', coaches);
        },
        async fetchCoachEmail({dispatch}, payload) {
            const userId = payload.userId;

            const response = await axios.get(REST_API_URL +  '/users/?filters[userId][$eq]=' + userId, {
                headers: {
                    'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                }
            })

            const responseData = await response.data;

            if(response.ok == false) {
                const log = responseData.message || 'Error: Mailadresse von Coach konnte nicht bezogen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            return responseData[0].email;
        },
        async checkUserAuthenticationStatus({dispatch}, payload) {
            const email = payload.email;

            const existingUser = await axios.get(REST_API_URL +  '/users?filters[email][$eq]=' + email, {
                headers: {
                    'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                }
            })

            const responseData = await existingUser.data;

            if(existingUser.ok == false) {
                const log = responseData.message || 'Error: Eventuell vorhandener User konnte nicht überprüft werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            if(existingUser.data[0]) {
                return true;
            }

            return false;
        },
        async setNewsletterSubscriber({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const email = payload.email;
            const isSubscriber = payload.isSubscriber;
            let setSubscriber;

            if(JWT) {
                setSubscriber = await axios.put(REST_API_URL + '/users-permissions/me?populate=contact',
                    {
                        isSubscriber: isSubscriber
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + JWT,
                            'Content-Type': 'application/json',
                        }
                    }
                );
            } else {
                // subscription without login, existing User
                const findUser = await axios.get(REST_API_URL + '/users?filters[email][$eq]=' + email,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                        }
                    }
                );

                const user = await findUser.data;

                if(findUser.ok == false) {
                    const log = user.message || 'Error: User konnte nicht für die Markierung als Newsletter-Abonnent bezogen werden.';
                    dispatch('createLog', log, { root : true });
                    return;
                }

                setSubscriber = await axios.put(REST_API_URL + '/users/' + user[0].id,
                    {
                        isSubscriber
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                        }

                    }
                );
            }

            const subscriber = await setSubscriber.data;

            if(setSubscriber.ok == false) {
                const log = subscriber.message || 'Error: User konnte nicht als Newsletter-Abonnent markiert werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setUser', subscriber);
        },
        async addCourseToUser({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const id = payload.id;
            const purchases = payload.purchases;

            const response = await axios.put(REST_API_URL + '/contacts/' + id + '?populate=user',
                {
                    data: {
                        purchases: purchases
                    }
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                    }
                }
            )

            const contactData = await response.data;



            if(response.ok == false) {
                const log = contactData.message || 'Error: Es konnte keine Relation zwischen User und Produkt erstellt werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            let contact = contactData.data.attributes;
            contact['id'] = contactData.data.id;

            commit('setContact', contact);

        },
        async saveProductKey({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const slug = payload.slug;

            const response = await axios.get(REST_API_URL + '/products/' + slug + '/subscribe', {
                headers: {
                    'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                }
            });

            const key = await response.data;

            if(response.ok == false) {
                const log = key.message || 'Error: Gesuchter Produktschlüssel konnte nicht bezogen werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            const saveKey = await axios.put(REST_API_URL + '/users-permissions/me?populate=contact',
                {
                    productKey: {
                        slug: slug,
                        key: key
                    }
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json',
                    }
                }
            );

            const userData = await saveKey.data;

            if(saveKey.ok == false) {
                const log = userData.message || 'Error: Produktschlüssel konnte nicht zu User hinzugefügt werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setUser', userData);
        },
        async editUser({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const user = payload.user ;
            let isSubscriber = false;

            const newsletter = await axios.get(REST_API_URL + '/newsletters?filters[email][$eq]=' + user.email, {
                headers: {
                    'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY
                }
            })

            const newsletterData = await newsletter.data;

            if(newsletter.ok == false) {
                const log = newsletterData.message || 'Error: User konnte bei Registrierung bzw. Änderung nicht auf Newsletter-Abonnement überprüft werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            if(newsletterData.data[0] && newsletterData.data[0].attributes.email === user.email) {
                isSubscriber = true;
            }

            let data = {
                gender: user.gender || null,
                firstName: user.firstName || null,
                lastName: user.lastName || null,
                street: user.street || null,
                zipCode: user.zipCode || null,
                city: user.city || null,
                phone: user.phone || null,
                isSubscriber: isSubscriber
            };

            if(user.confirmation) {
                data = {
                    emailConfirmation: true
                }
            }

            const response = await axios.put(REST_API_URL + '/users-permissions/me?populate=contact', data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json'
                    }
                }
            );

            const responseData = await response.data;

            if(response.ok == false) {
                const log = responseData.message || 'Error: User konnte nicht bearbeitet werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setUser',responseData);
        },
        async saveLastPosition({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const position = payload.position;

            const response = await axios.put(REST_API_URL + '/users-permissions/me?populate=contact',
                {
                    lastPosition: position
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json'
                    }
                }
            )

            const responseData = await response.data;

            if(response.ok == false) {
                const log = responseData.message || 'Error: Zuletzt besuchte Trainingseinheit konnte nicht erfasst werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setUser', responseData);
        },
        async updateModuleProgress({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const completed = payload.completed;

            const response = await axios.put(REST_API_URL + '/users-permissions/me?populate=contact',
                {
                    "completed": completed
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json',
                    }
                }
            )

            const responseData = await response.data;

            if(response.ok == false) {
                const log = responseData.message || 'Error: Trainings-Fortschritt des Users konnte nicht gespeichert werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setUser', responseData);
        },
        async saveNotes({dispatch, commit}, payload) {
            const JWT = payload.jwt;
            const notes = payload.notes;

            const response = await axios.put(REST_API_URL + '/users-permissions/me?populate=contact',
                {
                    notes: notes
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                        'Content-Type': 'application/json'
                    }
                }
            )

            const responseData = await response.data;

            if(response.ok == false) {
                const log = responseData.message || 'Error: Notizänderungen konnten nicht gespeichert werden.';
                dispatch('createLog', log, { root : true });
                return;
            }

            commit('setUser', responseData);
        },
        async clearUser({commit}) {
            commit('setUser', null);
            commit('setContact', null);
            commit('setContactPurchases', null);
        },
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        /*getContacts(state) {
            return state.contacts;
        },*/
        getContact(state) {
            return state.contact;
        },
        getContactPurchases(state) {
            return state.contactPurchases;
        },
        getCoaches(state) {
            return state.coaches;
        }
    }
}
