<template>

    <div class="reset-password-page">
        <div class="basic-form row">
            <div class="gr-24">
                <form @submit.prevent="validateFormData"
                      class="form gr-14 gr-18@small gr-20@tablet gr-22@mobile push-5 push-3@small push-2@tablet push-1@mobile">

                    <table>

                        <thead>

                            <tr>
                                <td></td>
                                <td>
                                    <h3>Neues Passwort setzen:</h3>
                                </td>
                            </tr>

                        </thead>

                        <tbody>

                            <tr>
                                <th>
                                    <label for="password">Passwort</label>
                                </th>
                                <td class="password-field">
                                    <input :type="passwordType" id="password" name="password" autocomplete="off"
                                           v-model="user.password" @focus="setPasswordTypeOnEvent"
                                           @blur="setPasswordTypeOnEvent" />
                                    <div class="password-symbol"
                                         :class="'password-symbol-' + passwordDisplay.reset.symbol"
                                         @click="setPasswordTypeOnSymbol">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td class="error" v-if="validity.password == false">
                                    Dein Passwort muss mindestens 8 Zeichen lang sein.
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label for="confirm-password">Passwort<br>bestätigen</label>
                                </th>
                                <td>
                                    <input :type="passwordType" id="confirm-password" name="confirmPassword"
                                           v-model="user.confirmPassword" @focus="setPasswordTypeOnEvent"
                                           @blur="setPasswordTypeOnEvent">
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td class="error" v-if="validity.confirmPassword == false">
                                    Passwörter stimmen nicht überein.
                                </td>
                            </tr>

                        </tbody>

                        <tfoot>

                            <tr>
                                <td colspan="2">
                                    <button type="submit" class="order-button button">zurücksetzen</button>
                                </td>
                            </tr>

                        </tfoot>

                    </table>

                </form>
            </div>
        </div>
    </div>

</template>f

<script>
    export default {
        inject: ['passwordDisplay', 'togglePassword', 'setNotice'],
        data() {
            return {
                user: {
                    password: '',
                    confirmPassword: '',
                },
                validity: {
                    password: true,
                    confirmPassword: true
                },
                passwordType: 'password',
            }
        },
        methods: {
            setPasswordTypeOnEvent(event) {
                let type = event.target.type;
                let value = event.target.value;

                if(type === 'blur' && !value) {
                    this.passwordType = 'text'
                } else {
                    this.passwordType = this.passwordDisplay['reset'].type;
                }
            },
            async setPasswordTypeOnSymbol() {
                await this.togglePassword('reset');
                this.passwordType = this.passwordDisplay['reset'].type;
            },
            validateFormData() {
                if (this.user.password.length < 8) {
                    this.validity.password = false;
                } else {
                    this.validity.password = true;
                }

                if (this.validity.password && this.user.confirmPassword !== this.user.password) {
                    this.validity.confirmPassword = false;
                } else {
                    this.validity.confirmPassword = true;
                }

                if(this.validity.password && this.validity.confirmPassword) {
                    this.submit();
                }
            },
            async submit() {
                if(this.code) {
                    await this.$store.dispatch('auth/resetForgottenPassword', {
                        code: this.code,
                        password: this.user.password,
                        passwordConfirmation: this.user.confirmPassword
                    })

                    this.setNotice('Dein Passwort wurde erfolgreich zurückgesetzt, du kannst dich jetzt anmelden.', 'OK', 'login');
                }

                this.$router.replace( { name: 'home' } );
            }
        },
        computed: {
            code() {
                return this.$route.query.code;
            }
        }
    }
</script>

<style scoped>
</style>
