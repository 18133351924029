<template>
    <header>

        <div class="header">
            <div class="row">
                <div class="gr-24">

                    <div class="header-logo gr-9 gr-14@small gr-24@mini">
                        <div class="row">
                            <router-link v-if="!$route.path.includes('/portal')" class="header-logo-img gr-14 push-4 push-2@small" :to="{ name: 'home' }">
                                <img src="../../images/logo.svg" alt="Starkes Mindset">
                            </router-link>
                        </div>
                    </div>

                    <div class="header-menu gr-8">
                        <div class="row">
                            <a class="header-menu-login gr-5" @click="setAuthMode('login')" v-if="!isAuthenticated">Login</a>
                            <template v-else-if="!isLoading">
                                <a class="header-menu-login gr-5" @click="logout">Logout</a>
<!--                                <a @click="setAuthMode('reset')">Passwort zurücksetzen</a>-->
                                <router-link :to="{ name: 'portalOverview' }">Portal</router-link>
                            </template>
                        </div>
                    </div>

                    <div v-if="!$route.path.includes('/portal')" class="header-title gr-6 push-1 push-0@desktop">
                        <img class="push-1" src="../../images/label.svg" alt="Starkes Mindset">
                    </div>

                    <div v-else class="header-logo-courses gr-5 push-1">
                        <div class="row">
                            <a class="header-title-logo gr-22 push-2@small" href="/">
                                <img src="../../images/logo-2.svg" alt="Starkes Mindset">
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="header-mobile" :class="{ 'courses': $route.path.includes('/portal') }">

            <div class="row">

                <div v-if="!$route.path.includes('/portal')" class="gr-24">
                    <div class="header-title gr-8@small gr-20@mobile push-16@small push-3@mobile">
                        <img class="push-1@small push-0@mobile" src="../../images/label.svg" alt="Starkes Mindset">
                    </div>
                </div>

                <div v-else class="gr-24">
                    <div class="header-logo-courses gr-6@small gr-7@tablet push-16@small push-15@tablet gr-9@mobile push-13@mobile">
                        <div class="row">
                            <a class="header-logo-courses-img" href="/">
                                <img src="../../images/logo-2.svg" alt="Starkes Mindset">
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="gr-24">
                    <div class="header-menu gr-8@small gr-11@tablet gr-24@mobile push-14@small push-13@tablet push-0@mobile">
                        <a class="header-menu-login gr-16@small gr-4@mobile push-2@tablet" @click="setAuthMode('login')" v-if="!isAuthenticated">Login</a>
                        <template v-else-if="!isLoading">
                            <a class="header-menu-login gr-16@small gr-4@mobile push-2@tablet" @click="logout">Logout</a>
<!--                            <a @click="setAuthMode('reset')">Passwort zurücksetzen</a>-->
                            <base-button :to="{ name: 'portalOverview' }" class="button" link>Portal</base-button>
                        </template>
                    </div>
                </div>
            </div>
        </div>

    </header>
</template>

<script>
    export default {
        props: ['isLoading', 'isAuthenticated'],
        inject: ['setAuthMode', 'logout'],
        computed: {
            productPage() {
                return this.$route.name === 'course'? true : false;
            }
        }
    }
</script>

<style scoped>
</style>
