<template>

    <teleport to="head">
        <title>{{ metaData.title }}</title>
        <meta property="og:title" :content="metaData.ogTitle">
        <meta name="description" :content="metaData.description">
        <meta property="og:description" :content="metaData.ogDescription">
        <meta property="og:image" :content="metaData.ogImage">
    </teleport>

    <transition name="fade">
        <div class="loading-page" v-if="isLoading">
            <base-spinner></base-spinner>
        </div>
    </transition>

    <div class="wrapper container-centered">

        <div class="top-sidebar" v-if="currentPage === 'home' || currentPage === 'coach' || currentPage === 'course'">
            <div class="row">
                <div class="gr-24">
                    <div class="top-sidebar-content fixed gr-9 gr-16@small gr-24@mobile">
                        <img src="./images/header-sidebar.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="placeholder" v-else></div>

        <the-header :isLoading="isLoading" :isAuthenticated="isAuthenticated"></the-header>

        <main class="main" :class="{'no-sidebar': currentPage == 'privacy' || currentPage == 'imprint'}">
            <div class="content-container">

                <transition name="fade">
                    <user-authentication v-if="authMode" :authIsLoading="authIsLoading" :authMode="authMode" :authModeAddition="authModeAddition" :authData="authData" :loggedInUser="user" @close-form="closeForm"></user-authentication>
                </transition>

                <transition name="fade">
                    <router-view :isLoading="isLoading" :setLoading="setLoading" :orderIsLoading="orderIsLoading" :authData="authData" :isAuthenticated="isAuthenticated" :user="user" :contact="contact" :products="products" :purchases="purchases" :coaches="coaches" :isSubscriber="isSubscriber" :videoIsLoading="videoIsLoading" :videoIsPlaying="videoIsPlaying" :displayNewsletterForm="displayNewsletterForm" :displayNewsletterComment="displayNewsletterComment"></router-view>
                </transition>

            </div>
        </main>

        <the-footer></the-footer>

        <teleport to="#app">

            <transition name="fade">

                <div class="popup-view" v-if="errorMessage && !isLoading && !authIsLoading && !orderIsLoading">
                    <pop-up class="popup" @click.stop>
                        <div class="popup-text">
                            {{ errorMessage }}
                        </div>
                        <base-button class="popup-button button" type="button" @click="closeErrorMessage">{{ popupButton }}</base-button>
                    </pop-up>
                </div>

            </transition>

            <transition name="fade">

                <div class="popup-view" v-if="notice && !isLoading && !authIsLoading && !orderIsLoading" @click="noticeRef? handleNotice() : ''">
                    <pop-up class="popup">
                        <div class="popup-text">
                            {{ notice }}
                        </div>
                        <base-button class="popup-button button" type="button" @click="closeNotice">{{ popupButton}}</base-button>
                    </pop-up>
                </div>

            </transition>

            <transition name="fade">

                <div class="popup-view" v-if="confirmationNotice && !isLoading && !authIsLoading && !orderIsLoading">
                    <pop-up class="popup">
                        <div class="popup-text">
                            {{ confirmationNotice }}
                        </div>
                        <div class="confirmation-button-container">
                            <base-button class="confirmation-button button" type="button" color="#54990852" @click="handleConfirmation">{{ confirmationButton }}</base-button>
                            <base-button class="confirmation-button button" type="button" @click="closeConfirmationNotice">{{ declineButton }}</base-button>
                        </div>
                    </pop-up>
                </div>

            </transition>

        </teleport>
    </div>

</template>

<script>
    import axios from 'axios';
    import { defineAsyncComponent } from 'vue';
    const UserAuthentication = defineAsyncComponent(() => import('./components/auth/UserAuthentication.vue'));

    import './css/app.css';

    import { DIGISTORE_PRODUCT_URL } from './data/links.js';

    let passwordOptions = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!§$#'
    let passwordOptionsArray = passwordOptions.split('');
    let passwordOptionsLength = passwordOptionsArray.length - 1;

    function rand(max, min) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    export default {
        name: 'App',
        components: {
          UserAuthentication
        },
        provide() {
            return {
                setMetaData: this.setMetaData,
                setLoading: this.setLoading,
                setAuthLoading: this.setAuthLoading,
                setOrderLoading: this.setOrderLoading,
                loadPage: this.loadPage,
                fetchUser: this.fetchUser,
                setAuthMode: this.setAuthMode,
                passwordDisplay: this.passwordDisplay,
                togglePassword: this.togglePassword,
                checkUserAuthenticationStatus: this.checkUserAuthenticationStatus,
                checkUserPurchases: this.checkUserPurchases,
                handleOrderProcess: this.handleOrderProcess,
                submitNewsletter: this.submitNewsletter,
                submitCoachContactForm: this.submitCoachContactForm,
                fetchContactPurchases: this.fetchContactPurchases,
                closeForm: this.closeForm,
                setError: this.setError,
                setNotice: this.setNotice,
                setConfirmationNotice: this.setConfirmationNotice,
                setVideoLoading: this.setVideoLoading,
                toggleVideo: this.toggleVideo,
                logout: this.logout,
            }
        },
        data() {
            return {
                metaData: {
                    title: 'Starkes Mindset * Fitnessstudio für den Kopf',
                    ogTitle: 'Starkes Mindset * Fitnessstudio für den Kopf',
                    description: 'Die 9+1 Tage Challenge für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                    ogDescription: 'Die 9+1 Tage Challenge für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                    ogImage: 'https://starkes-mindset.com/img/og-starkes-mindset.jpg'
                },
                isLoading: false,
                authIsLoading: false,
                orderIsLoading: false,
                authMode: null,
                authModeAddition: {},
                passwordDisplay: {
                    login: {
                        type: 'password',
                        symbol: 'eye',
                    },
                    reset: {
                        type: 'password',
                        symbol: 'eye',
                    },
                },
                errorMessage: null,
                popupButton: '',
                notice: null,
                noticeRef: false,
                noticeData: null,
                confirmationNotice: null,
                confirmationRef: '',
                confirmationButton: '',
                declineButton: '',
                confirmationData: null,
                videoIsLoading: {},
                videoIsPlaying: {},
                displayNewsletterForm: true,
                displayNewsletterComment: false,
                newsletter: false,
                confirmUser: true,
                temporaryPassword: null,
                orderProcessWithFees: false,
            }
        },
        watch: {
            $route: ['beforeRouteEnter'],
        },
        async mounted() {
            await this.loadPage();

            let afterConfirmation = this.$route.query['willkommen'] || false;

            if(afterConfirmation) {
                if(!this.user) {
                    this.setAuthMode('login', 'first-login');
                } else {
                    this.$router.replace({ name: 'home' });
                }
            }
        },
        methods: {
            setMetaData(meta = false) {
                let data = meta || this.standardMetaData;

                for(let [key, value] of Object.entries(data)) {
                    this.metaData[key] = value;
                }
            },
            scrollToTop() {
                window.scrollTo(0,0);
            },
            setLoading(state) {
               this.isLoading = state;
            },
            setAuthLoading(state) {
                this.authIsLoading = state;
            },
            setOrderLoading(state) {
                this.orderIsLoading = state;
            },
            async loadPage() {
                this.fetchProducts();
                await this.fetchCoaches();
                await this.checkLoginState();

                if(this.isAuthenticated) {
                    await this.fetchUser();
                    this.fetchContact();
                }
            },
            async fetchProducts() {
                await this.$store.dispatch('products/fetchProducts');
            },
            async fetchCoaches() {
                await this.$store.dispatch('users/fetchCoaches');
            },
            async checkLoginState() {
                await this.$store.dispatch('auth/autoLogin');
            },
            setAuthMode(mode, ref = false, data = null) {
                this.closeForm();
                this.authMode = mode;
                this.authModeAddition.reference = ref;
                this.authModeAddition.data = data;
            },
            togglePassword(ref) {
                if(this.passwordDisplay[ref].type === 'password') {
                    this.passwordDisplay[ref].type = 'text';
                    this.passwordDisplay[ref].symbol = 'eye-closed';
                } else {
                    this.passwordDisplay[ref].type = 'password';
                    this.passwordDisplay[ref].symbol = 'eye';
                }
            },
            async fetchUser() {
                const checkUser = await this.$store.dispatch('users/fetchUser', {
                    jwt: this.authData.jwt
                });

                // autologout
                if(!checkUser && this.confirmUser) {
                    this.setNotice('Du wurdest automatisch abgemeldet');
                    this.confirmedLogout();
                }
            },
            async fetchContact() {
                await this.$store.dispatch('users/fetchContact', {
                    userId: this.user.userId
                })
            },
            async fetchContactPurchases(contactId) {
                await this.$store.dispatch('users/fetchContactPurchases', {
                    contactId: contactId
                })
            },
            async signup(userData, confirmation = false) {
                let userId = this.generateUserId();
                let password = this.generatePassword();

                if(confirmation) {
                    this.temporaryPassword = password;
                }

                let user = {
                    userId: userId,
                    firstName: userData.firstName || false,
                    lastName: userData.lastName || false,
                    email: userData.email,
                    username: userData.email,
                    password: password,
                }

                await this.$store.dispatch('auth/signup', {
                    user,
                });

                let error = this.$store.getters['auth/getErrorMessage'] || false;

                if(error) {
                    this.setError(error);
                    this.$store.dispatch('auth/clearErrorMessage');

                    if(this.orderProcessWithFees) {
                        this.$router.replace({ name: 'home' });
                        this.orderProcessWithFees = false;
                        this.setOrderLoading(false);
                    }

                    return;
                }

                this.$store.dispatch('createLog', 'User angelegt.');

                await this.fetchUser();
                await this.createContact(userData);

                await this.fetchUser();
                await this.fetchContact();

                this.scrollToTop();

                let greeting = user.firstName? 'Willkommen, ' + user.firstName + '!' : 'Willkommen!';

                if(!confirmation) {
                    this.setNotice(greeting + ' Das Passwort zu deinem Account: ' + password);
                }
            },
            generatePassword() {
                let password = '';

                for(let i = 0; i <= 25; i++) {
                    password += passwordOptionsArray[rand(0, passwordOptionsLength)]
                }

                return password;
            },
            generateUserId() {
                let userId = '';

                for(let i = 0; i <= 20; i++) {
                    userId += passwordOptionsArray[rand(0, passwordOptionsLength)]
                }

                return userId;
            },
            async createContact(data = false) {
                await this.$store.dispatch('users/createContact', {
                    jwt: this.authData.jwt,
                    userId: this.user.userId,
                    additionalData: data,
                })

                this.$store.dispatch('createLog', 'Kontakt zu existentem User angelegt.');
            },
            setError(error, button = 'OK') {
                this.errorMessage = error;
                this.popupButton = button;
            },
            setNotice(notice, button = 'OK', ref = false, data = null) {
                this.notice = notice;
                this.popupButton = button;

                this.noticeRef = ref;
                this.noticeData = data;
            },
            handleNotice() {
                this.closeNotice();

                if(this.noticeRef === 'multipleNotices') {
                    this.setNotice(this.noticeData);
                }

                if(this.noticeRef === 'login' || this.noticeRef === 'signup') {
                    this.setAuthMode(this.noticeRef, this.authModeAddition.reference, this.authModeAddition.data);
                }
            },
            setConfirmationNotice(text, ref, confButton = 'Ja', declButton = 'Nein', data = null) {
                this.confirmationNotice = text;
                this.confirmationRef = ref;
                this.confirmationButton = confButton;
                this.declineButton = declButton;
                this.confirmationData = data;
            },
            handleConfirmation() {
                if(this.confirmationRef === 'first-login') {
                    this.setAuthMode('reset');
                }

                if(this.confirmationRef === 'logout') {
                    this.confirmedLogout();
                    this.closeForm();
                }

                this.closeConfirmationNotice();
            },
            closeForm() {
                this.authMode = '';
                this.setLoading(false);
            },
            async closeErrorMessage() {
                this.errorMessage = null;
                await this.$store.dispatch('auth/clearErrorMessage');
            },
            closeNotice() {
                this.notice = null;
            },
            closeConfirmationNotice() {
                this.confirmationNotice = null;
            },
            setVideoLoading(option, slug) {
                if(!slug) {
                    this.videoIsLoading = option;
                } else {
                    this.videoIsLoading[slug] = option;
                }
            },
            toggleVideo(slug, ref) {
                if(this.videoIsPlaying[slug]) {
                    ref.pause();
                    this.videoIsPlaying[slug] = false;
                    this.setVideoLoading(false, slug);
                } else {
                    ref.play();
                    this.stopVideos();
                    this.videoIsPlaying[slug] = true;
                    this.setVideoLoading(true, slug);
                }
            },
            stopVideos() {
                this.videoIsPlaying = {};
                this.videoIsLoading = {};
            },
            async checkUserAuthenticationStatus(user, product = false, newsletter = false) {

                localStorage.setItem('newsletter', JSON.stringify(newsletter));
                const existingUser = await this.$store.dispatch('users/checkUserAuthenticationStatus', {
                    email: user.email
                });

                if(existingUser) {

                    if(product) {
                        let data = {
                            product: product,
                            newsletter: newsletter,
                            user: user
                        };

                        const userIsConfirmed = await this.$store.dispatch('auth/checkUserConfirmation', {
                            user
                        });

                        if(userIsConfirmed) {
                            this.setAuthMode('login', 'order', data);
                        } else {
                            const error = 'Bitte bestätige zuerst deine Mailadresse über Klick auf den Link, den wir dir nach deiner Registrierung per Mail zugesandt haben.';
                            this.setError(error);

                            this.$store.dispatch('createLog', 'Login-Versuch von inaktivem User ohne vorherige Bestätigung der Mailadresse.');
                        }

                        this.setLoading(false);

                    } else if(newsletter) {
                        await this.markUserAsSubscriber(user, user.email);
                    }

                } else {

                    if(product) {

                        if(product.freebie) {
                            this.handleOrderProcess(false, product, user, true);
                        } else {
                            this.$store.dispatch('createLog', 'Überprüfung von Login-Status des Users.');
                            return this.openOrderForm(user, product);
                        }
                    } else if(!product && newsletter) {
                        await this.signup(user, true);
                        await this.editUser(user);
                        this.confirmUser = false;
                        await this.markUserAsSubscriber(user);
                    }
                }

                this.$store.dispatch('createLog', 'Überprüfung von Login-Status des Users.');

            },
            async checkUserPurchases(product, newsletter = false) {

                await this.fetchContactPurchases(this.contact.id);

                let purchases = this.purchases || false;

                let existingProduct = false;

                if(purchases) {
                    existingProduct = purchases.filter(purchase => purchase.slug === product.slug);
                }

                if(existingProduct.length) {
                    await this.setError('Du hast diesen Kurs bereits erworben.');

                    if(newsletter) {
                        await this.submitNewsletter(this.user);
                    }

                    this.$router.replace({ name: 'portal' });

                } else {
                    localStorage.setItem('newsletter', JSON.stringify(newsletter));

                    if(product.freebie) {
                        this.handleOrderProcess(false, product, this.user);

                    } else {
                        this.openOrderForm(this.user, product);
                    }
                }

                this.$store.dispatch('createLog', 'Überprüfung von Produktkäufen des Users.');
            },
            async openOrderForm(user, product) {

                // no freebie
                let url = DIGISTORE_PRODUCT_URL + '/' + product['product_id'];
                let firstName = user.firstName;
                let lastName = user.lastName;
                let phone = user.phone || '';

                window.location = `${url}?first_name=${firstName}&&last_name=${lastName}&&email=${user.email}&&phone_no=${phone}`;

                this.$store.dispatch('createLog', 'Bestellformular erstellt.');
            },
            async handleOrderProcess(purchaseId = false, freebie = false, freebieUser = false, signup = false) {

                await this.fetchProducts();

                let product;
                let isFreebie;
                let buyer;
                let transactionId;
                let buyerData = {};
                let orderIds = false;

                if(!freebie) {
                    this.$store.dispatch('createLog', 'Erwerb von kostenpflichtigem Produkt.');

                    isFreebie = false;
                    let orderData = await this.$store.dispatch('products/fetchOrderData', {
                        location: this.location,
                        purchaseId: purchaseId,
                        products: this.products.all
                    });

                    if (!orderData)
                      return;

                    this.$store.dispatch('createLog', 'Zahlung über Digistore.');

                    buyer = orderData['buyer'];
                    transactionId = orderData['transactionId'];

                    orderIds = {
                        order: purchaseId,
                        transaction: transactionId,
                    };

                    product = this.product;
                    this.orderProcessWithFees = true;

                    await this.$store.dispatch('products/eraseOrderData');

                    this.$store.dispatch('createLog', 'Bezug von Bestellformulardaten nach Produktkauf.');

                } else {
                    this.$store.dispatch('createLog', 'Erwerb von Freebie.');

                    isFreebie = true;

                    let userData = this.user || freebieUser;
                    buyer = {
                        'first_name':  userData.firstName,
                        'last_name': userData.lastName,
                        email: userData.email,
                        'phone_no': userData.phone,
                    }

                    product = freebie;
                }

                buyerData = {
                    gender: buyer['salutation'] === 'M' ? 'male' : (buyer['salutation'] === 'F' ? 'female' : null),
                    firstName: buyer['first_name'],
                    lastName: buyer['last_name'],
                    username: buyer.email,
                    email: buyer.email,
                    street: buyer.street || null,
                    zipCode: buyer['zipcode'] || null,
                    city: buyer.city || null,
                    phone: buyer['phone_no'] || null,
                }

                if(signup) {
                    await this.signup(buyerData, isFreebie);
                    this.$store.dispatch('createLog', 'Registrierung nach Produktkauf.');

                    await this.editUser(buyerData);
                    this.confirmUser = !isFreebie;
                }

                if(this.user && this.user.email === buyerData.email) {
                    this.handlePayment(product, isFreebie, orderIds);

                } else {
                    this.$router.replace({ name: 'home' });
                }
            },
            async handlePayment(product, isFreebie, orderIds = false) {

                await this.$store.dispatch('products/handlePayment', {
                    jwt: this.authData.jwt,
                    product: product,
                    user: this.user,
                    freebie: isFreebie,
                    orderIds: orderIds,
                });

                this.$store.dispatch('createLog', 'Bestelldaten hinzugefügt.');

                this.addCourseToUser(product);
            },
            async addCourseToUser(product) {

                let purchases = [];
                let challengeCode = false;

                await this.fetchContactPurchases(this.contact.id);

                const purchaseData = this.purchases || false;

                if(purchaseData) {
                    for(let key in purchaseData) {
                        purchases.push(purchaseData[key].id);
                    }
                }

                purchases.push(product.id);

                // to Contact (as relation, public)
                await this.$store.dispatch('users/addCourseToUser', {
                    jwt: this.authData.jwt,
                    id: this.contact.id,
                    purchases: purchases
                });

                await this.fetchContact();

                // to User (as key, private)
                await this.$store.dispatch('users/saveProductKey', {
                    jwt: this.authData.jwt,
                    slug: product.slug,
                });

                // fetch challenge video code
                if(product.type === 'challenge') {

                    await this.$store.dispatch('products/fetchCourseContent', {
                            jwt: this.authData.jwt,
                            slug: product.slug,
                            productType: product.type,
                    });

                    const courseContent = this.$store.getters['products/getCourseContent'] || false;

                    if(courseContent && courseContent.challengeCode) {
                        challengeCode = courseContent.challengeCode;
                    }
                }

                this.$store.dispatch('createLog', 'Produkt-Relation zu User nach Kauf erstellt.');

                await this.loadPage();
                await this.sendOrderForm(this.user, product, challengeCode);

                if(JSON.parse(localStorage.getItem('newsletter'))) {
                    await this.submitNewsletter(this.user);
                    localStorage.removeItem('newsletter');
                } else {
                    if(!this.confirmUser) {
                        this.blockUser(this.user);
                    } else {
                        if(this.isAuthenticated) {
                            if(this.orderProcessWithFees) {
                                this.orderProcessWithFees = false;
                                this.setOrderLoading(false);
                            } else {
                                await this.$router.push({ name: 'portal' });
                            }
                        }
                        this.setLoading(false);
                    }
                }

                if(product.freebie) {
                    this.$store.dispatch('createLog', 'Bestellvorgang von Freebie abgeschlossen.');
                } else {
                    this.$store.dispatch('createLog', 'Bestellvorgang von kostenpflichtigem Produkt abgeschlossen.');
                }
            },
            async blockUser(userData) {
                let user = {
                    firstName: userData.firstName || false,
                    email: userData.email,
                    confirmation: true
                }

                await this.editUser(user);
                this.confirmedLogout();

                this.scrollToTop();

                let greeting = user.firstName? 'Willkommen, ' + user.firstName + '!' : 'Willkommen!';
                this.setNotice(greeting + ' Wir haben dir eine Mail zur Bestätigung deiner Mailadresse geschickt. Das Password zu deinem Account: ' + this.temporaryPassword);

                this.temporaryPassword = null;

                this.$store.dispatch('createLog', 'User bis zu Aktivierung über Bestätigungslink blockiert.');

                this.setLoading(false);
            },
            async sendOrderForm(user, productData, challengeCode = false) {

                let product = productData;
                product.challengeCode = challengeCode;

                let data = new FormData();

                data['token'] = 'A4GPqFTvGXEfrGsiWoBtqy';
                data['firstName'] = user.firstName;
                data['lastName'] = user.lastName;
                data['email'] = user.email;
                data['phone'] = user.phone || null;
                data['product'] = product;

                const response = await axios.post(this.location + '/php/sendOrderForm.php', {
                    data
                });

                const responseData = await response.data;

                if(response.ok == false) {
                    const log = responseData.message || 'Versand der Bestellbestätigungen fehlgeschlagen.';
                    await this.$store.dispatch('createLog', log, { root : true });
                }

                this.$store.dispatch('createLog', 'Bestellbestätigungen versandt.');
            },
            async submitNewsletter(userData) {

                await this.$store.dispatch('newsletter/addNewsletterSubscriber', {
                    email: userData.email
                });

                let error = await this.$store.getters['newsletter/getErrorMessage'] || false;

                if(error) {
                    this.setError(error);
                    this.$store.dispatch('newsletter/clearErrorMessage');
                    if(!this.confirmUser) {
                        return this.blockUser(userData);
                    } else {
                        if(this.isAuthenticated) {
                            if(this.orderProcessWithFees) {
                                this.orderProcessWithFees = false;
                                this.setOrderLoading(false);
                            } else {
                                await this.$router.replace({ name: 'portal' });
                            }
                        }
                        return this.setLoading(false);
                    }
                }

                this.$store.dispatch('createLog', 'Newsletter-Abonnent hinzugefügt.');

                if(!this.isAuthenticated) {
                    await this.checkUserAuthenticationStatus(userData, false, true);
                } else {
                    this.markUserAsSubscriber(userData);
                }
            },
            async markUserAsSubscriber(userData, email = false) {

                await this.$store.dispatch('users/setNewsletterSubscriber', {
                    jwt: this.user? this.authData.jwt : false,
                    email: email,
                    isSubscriber: true
                })

                this.displayNewsletterForm = false;
                this.displayNewsletterComment = true;

                await this.sendNewsletterForm(userData);

                if(email) {
                    // registered User without login
                    await this.clearUser();
                }

                if(!this.confirmUser) {
                    await this.blockUser(userData);
                } else {
                    if(this.isAuthenticated) {
                        if(this.orderProcessWithFees) {
                            this.orderProcessWithFees = false;
                            this.setOrderLoading(false);

                        } else {
                            await this.$router.replace({ name: 'portal' });
                        }
                    }
                    this.setLoading(false);
                }

                this.$store.dispatch('createLog', 'User als Newsletter-Abonnent gekennzeichnet.');

            },
            async sendNewsletterForm(userData) {

                let data = new FormData();

                data['token'] = 'A4GPqFTvGXEfrGsiWoBtqy';
                data['email'] = userData.email;
                data['firstName'] = userData.firstName;
                data['lastName'] = userData.lastName;
                data['phone'] = userData.phone || false;
                data['newsletter'] = true;

                const response = await axios.post(this.location + '/php/sendNewsletterForm.php', {
                    data
                })

                const responseData = await response.data;

                if(response.ok == false) {
                    const log = responseData.message || 'Versand der Anmeldebestätigung zum Newsletter fehlgeschlagen.';
                    await this.$store.dispatch('createLog', log, { root : true });
                }

                this.$store.dispatch('createLog', 'Mail zu Newsletter-Anmeldung versandt.');

            },
            async submitCoachContactForm(userId, userData) {

                let coachEmail = await this.$store.dispatch('users/fetchCoachEmail', {
                    userId
                });

                if(coachEmail) {
                    this.sendCoachContactForm(coachEmail, userData);
                }
            },
            async sendCoachContactForm(coach, userData) {

                let data = new FormData();

                data['token'] = 'A4GPqFTvGXEfrGsiWoBtqy';
                data['coach'] = coach;
                data['firstName'] = userData.firstName;
                data['lastName'] = userData.lastName;
                data['email'] = userData.email;
                data['phone'] = userData.phone;
                data['message'] = userData.message;
                data['privacy'] = true;

                const response = await axios.post(this.location + '/php/sendCoachContactForm.php', {
                    data
                })

                const responseData = await response.data;

                if (response.ok == false) {
                    const log = responseData.message || 'Versand der Coach-Anfrage fehlgeschlagen.';
                    await this.$store.dispatch('createLog', log, { root : true });
                }

                this.$store.dispatch('createLog', 'Coach-Kontaktformular versandt.');
            },
            async editUser(user) {
                await this.$store.dispatch('users/editUser', {
                    jwt: this.authData.jwt,
                    user: user
                })
            },
            async logout() {
                this.setConfirmationNotice('Bist du sicher, dass du dich abmelden möchtest?', 'logout', 'Abmelden', 'Abbrechen');
            },
            async confirmedLogout() {
                await this.$store.dispatch('auth/logout');
                await this.resetData();
                this.confirmUser = true;
                this.$router.replace({name: 'home'});
            },
            async resetData() {
                await this.clearUser();
                this.displayNewsletterForm = true;
                this.displayNewsletterComment = false;
                await this.loadPage;
            },
            async clearUser() {
                await this.$store.dispatch('users/clearUser');
            },
            async beforeRouteEnter(to, from) {
                this.setMetaData();

                let authCheck = await JSON.parse(localStorage.getItem('authData'));

                if(to.meta.requiresAuth && !authCheck) {
                    this.$router.replace({ name: 'home' });
                }/* else {
                    let params = '';

                    if(to.params) {
                        params = {};

                        for(let [index, param] of Object.entries(to.params)) {
                            params[index] = param;
                        }
                    }

                    this.$router.push({ name: to.name, params: params, hash: to.hash, query: to.query });
                }*/

                if(to.name === 'thankYou' && !to.query['purchase_id']) {
                    this.$router.replace({ name: 'home' });
                }

                if(to.name == from.name && to.hash == '#signup') {
                    return;

                } else {
                    this.stopVideos();
                }
            },
        },
        computed: {
            standardMetaData() {
                let metaData = {
                    title: 'Starkes Mindset * Fitnessstudio für den Kopf',
                    ogTitle: 'Starkes Mindset * Fitnessstudio für den Kopf',
                    description: 'Die Plattform für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                    ogDescription: 'Die Plattform für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                    ogImage: 'https://starkes-mindset.com/img/og-starkes-mindset.jpg'
                };

                return metaData;
            },
            location() {
                let url = 'http://localhost:8080';
                if(process.env.NODE_ENV === 'production') {
                    url = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
                }
                return url;
            },
            currentPage() {
                return this.$route.name;
            },
            products() {
                const allProducts = this.$store.getters['products/getProducts'] || false;

                let products = {};

                if(allProducts) {
                    products['all'] = allProducts;

                    let productTypes = ['challenge', 'webinar', 'training'];

                    productTypes.forEach(type => {
                        this[type+"s"] = allProducts.filter(product => product.type === type)
                        products[type+"s"] = this[type+"s"];
                    })

                    return products;
                } else {
                    return false;
                }
            },
            purchases() {
                return this.$store.getters['users/getContactPurchases'] || false;
            },
            /*contacts() {
                return this.$store.getters['users/getContacts'] || false;
            },*/
            /*
            when platform: fetch contacts, then filter coaches
            coaches() {

                let coaches = [];

                for(let key in this.contacts) {
                    const coach = this.contacts[key];

                    if(coach.coach) {
                        coaches.push(coach);
                    }
                }

                return coaches;
            },*/
            coaches() {
                return this.$store.getters['users/getCoaches'] || false;
            },
            isAuthenticated() {
                return this.$store.getters['auth/isAuthenticated'];
            },
            authData() {
                return this.$store.getters['auth/getAuthData'] || false;
            },
            user() {
                return this.$store.getters['users/getUser'] || false;
            },
            contact() {
                return this.$store.getters['users/getContact'] || false;
            },
            product() {
                return this.$store.getters['products/getProduct'] || false;
            },
            isSubscriber() {
                let isSubscriber = false;

                if(this.user && this.user.isSubscriber) {
                    isSubscriber = true;
                }
                return isSubscriber;
            }
        }
    }
</script>

<style>
    /* changes for branch style_addition */

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }

    .imprint u,
    .privacy u {
        text-underline-offset: .1em;
    }

    /**/

    /* temporary */

    .thank-you {
        margin-top: 5em;
        float: right;
    }

    /**/



</style>
