import { createApp, defineAsyncComponent } from 'vue';
import { createApolloProvider } from "@vue/apollo-option";
import apolloClient from "./vue-apollo";

import App from './App.vue';
import router from './router.js';
import store from './store/index.js';
import VimeoPlayer from 'vue-vimeo-player';

import './assets/css/main.scss'

import TheHeader from './components/layout/TheHeader.vue';
import TheFooter from './components/layout/TheFooter.vue';

const BaseButton = defineAsyncComponent(() => import('./components/ui/BaseButton.vue'));
const PopUp = defineAsyncComponent(() => import('./components/ui/PopUp.vue'));
const BaseSpinner = defineAsyncComponent(() => import('./components/ui/BaseSpinner.vue'));
const ProductDisplay = defineAsyncComponent(() => import('./components/products/ProductDisplay.vue'));

const app = createApp(App);

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

app.component('the-header', TheHeader);
app.component('the-footer', TheFooter);
app.component('base-button', BaseButton);
app.component('pop-up', PopUp);
app.component('base-spinner', BaseSpinner);
app.component('product-display', ProductDisplay);

app.use(router);
app.use(store);
app.use(VimeoPlayer);
app.use(apolloProvider);

app.mount('#app')
