<template>

    <div class="portal">

        <transition name="fade">
            <router-view :authData="authData" :user="user" :contact="contact" :products="products" :purchases="purchases" :videoIsPlaying="videoIsPlaying"></router-view>
        </transition>

    </div>

</template>

<script>
    export default {
        props: ['user', 'contact', 'authData', 'products', 'purchases', 'videoIsPlaying'],
        data() {
            return {
            }
        },
        async mounted() {
            await this.$router.push({ name: 'portalOverview' });
        },
    }
</script>


<style scoped>
</style>
