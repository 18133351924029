import axios from 'axios';
import { REST_API_URL, REST_API_AUTH_URL } from '../../../data/links.js';

export default {
    namespaced: true,
    state() {
        return {
            loggedIn: false,
            authData: null,
            errorMessage: null
        }
    },
    mutations: {
        setAuthData(state, data) {
            state.loggedIn = true;
            state.authData = data;
            state.errorMessage = null;
        },
        removeAuthData(state) {
            state.loggedIn = false;
            state.authData = null;
            state.errorMessage = null;
        },
        setErrorMessage(state, error) {
            state.errorMessage = error;
        }
    },
    actions: {
        async login({dispatch}, payload) {
            await dispatch('auth', {
                ...payload,
                mode: 'login'
            })

        },
        async autoLogin({commit}) {
            const authData = JSON.parse(localStorage.getItem('authData'));

            if(authData) {
                commit('setAuthData', authData);
            }
        },
        async signup({dispatch}, payload) {
            await dispatch('auth', {
                ...payload,
                mode: 'signup'
            })
        },
        async auth({dispatch, commit}, payload) {
            const user = payload.user;
            const confirmation = payload.confirmation || false;
            const mode = payload.mode;
            let response;
            let authData;

            try {

                if(mode === 'signup') {
                    response = await axios.post(REST_API_AUTH_URL + '/local/register', {
                        userId: user.userId,
                        username: user.email,
                        email: user.email,
                        password: user.password,
                        roles: ['subscriber'],
                        emailConfirmation: confirmation
                    });
                }

                else {
                    response = await axios.post(REST_API_AUTH_URL + '/local', {
                        identifier: user.email,
                        password: user.password
                    });
                }

                authData = await response.data;

                if(response.ok == false) {
                    const error = 'Authentifizierung fehlgeschlagen, bitte versuche es erneut.';
                    dispatch('setErrorMessage', error);

                    const log = 'Error: ' + authData.message || mode === 'signup'? 'Registrieren fehlgeschlagen.' : 'Login fehlgeschlagen.';
                    dispatch('createLog', log, { root : true });
                    return;
                }

            } catch(err) {
                const error = mode === 'login'? 'Email oder Passwort nicht korrekt, bitte versuche es erneut.' : 'Registrieren fehlgeschlagen, ein Benutzer mit dieser Mailadresse existiert bereits.'
                dispatch('setErrorMessage', error);
                return;
            }

            localStorage.setItem('authData', JSON.stringify(authData));

            commit('setAuthData', authData);
        },
        async checkUserConfirmation({dispatch}, payload) {
            const user = payload.user;

            const checkUser = await axios.get(REST_API_URL + '/users?filters[email][$eq]=' + user.email, {
                headers: {
                    'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY
                }
            })

            const userData = await checkUser.data;

            if(checkUser.ok == false) {
                const log = userData.message || 'Error: Validierung der User-Aktivierung vor Login nicht möglich';
                dispatch('createLog', log, { root : true });
            }

            if(userData[0].confirmed) {
                return true;
            } else {
                return false;
            }
        },
        async resetPassword({dispatch}, payload) {
            const JWT = payload.jwt;
            const newPassword = payload.newPassword;

            const response = await axios.put(REST_API_URL + '/users-permissions/me/reset-password?populate=contact',
                {
                    newPassword: newPassword
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + JWT,
                    }
                }
            )

            const user = await response.data;

            if (response.ok == false) {
                const error = 'Das Passwort konnte nicht zurückgesetzt werden. Bitte versuche es erneut.';
                dispatch('setErrorMessage', error);

                const log = user.message || 'Error: Passwort konnte nicht zurückgesetzt werden.';
                dispatch('createLog', log, {root: true});
            }
        },
        async forgotPassword({dispatch}, payload) {
            let email = payload.email;

            const user = await axios.get(REST_API_URL + '/users?filters[email][$eq]=' + email, {
                headers: {
                    'Authorization': 'Bearer ' + process.env.VUE_APP_STRAPI_API_KEY,
                }
            });

            const userData = await user.data;

            if(userData[0]) {

                if(userData[0].confirmed) {

                    const response = await axios.post(REST_API_AUTH_URL + '/forgot-password', {
                        email: email,
                        // url: url + '/reset-password'
                    });

                    const responseData = await response.data;

                    if (response.ok == false) {
                        const error = 'Fehlgeschlagen, bitte versuche es erneut.';
                        dispatch('setErrorMessage', error);

                        const log = responseData.message || 'Error: Bestätigungsmail zur Zurücksetzung des vergessenen Passworts konnte nicht versendet werden.';
                        dispatch('createLog', log, {root: true});
                    }

                } else {
                    const error = 'Bitte bestätige zuerst deine Mailadresse über Klick auf den Link, den wir dir per Mail zugesandt haben.';
                    dispatch('setErrorMessage', error);
                }

            } else {
                const error = 'Ein User mit dieser Mailadresse ist nicht bei uns registriert.';
                dispatch('setErrorMessage', error);
            }
        },
        async resetForgottenPassword({dispatch}, payload) {
            const { code, password, passwordConfirmation } = payload;

            const response = await axios.post(REST_API_AUTH_URL + '/reset-password', {
                code: code,
                password: password,
                passwordConfirmation: passwordConfirmation
            })

            const responseData = await response.data;

            if(response.ok == false) {
                const error = 'Die Zurücksetzung des Passworts ist fehlgeschlagen, bitte versuche es erneut.';
                dispatch('setErrorMessage', error);

                const log = responseData.message || 'Error: Vergessenes Passwort konnte nach Klick auf Bestätigungslink nicht zurückgesetzt werden.';
                dispatch('createLog', log, { root : true });
            }
        },
        async logout({commit}) {
            await localStorage.removeItem('authData');
            commit('removeAuthData');

        },
        setErrorMessage({commit}, error) {
            commit('setErrorMessage', error);
        },
        clearErrorMessage({commit}) {
            commit('setErrorMessage', null);
        },
    },
    getters: {
        isAuthenticated(state) {
            return state.loggedIn;
        },
        getAuthData(state) {
            return state.authData;
        },
        getErrorMessage(state) {
            return state.errorMessage;
        }
    }
}
