<template>
    <div class="home">
        <div class="product" v-for="(product, index) in products.all" :key="index">
            <product-display page="landingPage" :product="product" buttonText="weitere Infos"
                             :videoIsLoading="videoIsLoading" :videoIsPlaying="videoIsPlaying">
            </product-display>
        </div>

        <div class="row">
            <h1 class="home-caption gr-24 gr-21@small push-2@small gr-21@mobile push-1@mobile">Weitere Insights zum<br/>
                Fitnessstudio für den Kopf
            </h1>
        </div>

        <div class="row stream-row stream-row-one">
            <div class="stream gr-8 gr-22@mobile push-1@mobile">
                <iframe src="https://open.spotify.com/embed/episode/0JLEzDg02O72R8sgYEYyH5?utm_source=generator&theme=0"
                        width="100%" height="232" frameBorder="0" allowfullscreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
                </iframe>
            </div>

            <div class="stream gr-8 gr-22@mobile push-1@mobile">
                <iframe src="https://open.spotify.com/embed/episode/1iwFWm0FPNteAShVheTst7?utm_source=generator&theme=0"
                        width="100%" height="232" frameBorder="0" allowfullscreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
                </iframe>
            </div>

            <div class="stream gr-8 gr-22@mobile push-1@mobile">
                <iframe src="https://open.spotify.com/embed/episode/7jsJTY0BpLTzyIfoQkQXhN?utm_source=generator&theme=0"
                        width="100%" height="232" frameBorder="0" allowfullscreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
                </iframe>
            </div>
        </div>

        <div class="row stream-row stream-row-two">
            <div class="stream gr-8 gr-22@mobile push-1@mobile">
                <iframe src="https://player.vimeo.com/video/649619761?h=0fd04f3785" width="100%" height="288"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                </iframe>
            </div>

            <div class="stream gr-8 gr-22@mobile push-1@mobile">
                <iframe src="https://player.vimeo.com/video/410628148?h=bdc8666e62" width="100%" height="288"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                </iframe>
            </div>

            <div class="stream gr-8 gr-22@mobile push-1@mobile">
                <iframe src="https://player.vimeo.com/video/687192896?h=8cfb3bbbea#t=16s" width="100%" height="288"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                </iframe>
            </div>
        </div>

        <transition name="fade">
            <div class="row" v-if="(!isSubscriber && displayNewsletterForm) || showNewsletterComment">
                <div class="gr-24">
                    <div class="product-details-container-main gr-16 gr-20@small gr-24@mobile push-4 push-2@small push-0@mobile">
                        <form id="contact-form" @submit.prevent="sendNewsletter" v-if="displayNewsletterForm && !isSubscriber">
                        <div class="product-details-container-main-content">
                            <div>
                                <h3 class="gr-14 gr-18small gr-22@mini push-1">Haltet mich auf dem Laufenden!</h3>
                                <p class="gr-13 gr-18@small gr-22@mini push-1">
                                    Trage dich jetzt ein und sei vom Start weg dabei. Du erfährst alle Neuigkeiten zum Portal als Erste/r.
                                </p>

                                <p class="form-input-text push-1 gr-12 gr-18" style="clear:both;margin-bottom:0" v-if="!user">
                                    <input type="text" id="first-name" name="firstName"
                                           placeholder="Vorname" v-model="newsletter.firstName" required/>

                                    <input type="text" id="last-name" name="lastName"
                                           placeholder="Nachname" v-model="newsletter.lastName" required/>

                                    <input type="text" id="email" name="email"
                                           placeholder="e-mail" v-model="newsletter.email" required/>
                                </p>

                                <p class="form-input-checkbox push-1 gr-18" style="clear:both">
                                    <input type="checkbox" id="newsletter" name="newsletter" required/>
                                    <label for="newsletter">
                                        Ja, ich möchte euren Newsletter abonnieren und habe die Hinweise zum Datenschutz gelesen.
                                    </label>
                                </p>
                            </div>

                            <base-button type="submit" id="contact-form-button"
                                         class="product-details-container-main-content-button button push-1">
                                eintragen
                            </base-button>
                        </div>
                        </form>
                        <h3 id="form-success" class="gr-14 gr-18small gr-22@mini push-1"
                            v-else-if="showNewsletterComment">
                            Prima! Wir freuen uns auf dich.
                        </h3>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    export default {
        props: ['products', 'user', 'videoIsLoading', 'videoIsPlaying', 'displayNewsletterForm', 'displayNewsletterComment', 'isSubscriber'],
        inject: ['setMetaData', 'setLoading', 'submitNewsletter'],
        data() {
            return {
                newsletter: {},
                showNewsletterComment: false,
            }
        },
        watch: {
            displayNewsletterComment(curVal, oldVal) {
                if(curVal !== oldVal) {
                    this.showNewsletterComment = curVal
                }
            }
        },
        created() {
            this.setMetaData({
                title: 'Starkes Mindset * Fitnessstudio für den Kopf',
                ogTitle: 'Starkes Mindset * Fitnessstudio für den Kopf',
                description: 'Die 9+1 Tage Challenge für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                ogDescription: 'Die 9+1 Tage Challenge für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                ogImage: 'https://starkes-mindset.com/img/og-starkes-mindset.jpg'
            });
        },
        methods: {
            async sendNewsletter() {
                let userData = this.user || this.newsletter;
                this.setLoading(true);
                await this.submitNewsletter(userData);
                this.newsletter = {};
            },
        }
    }
</script>

<style scoped>
</style>
