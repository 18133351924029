<template>
    <div class="loading-page" v-if="orderIsLoading">
        <base-spinner></base-spinner>
    </div>

    <div v-else class="thank-you">
        <h1>Großartig, dass du dabei bist!</h1>
        <p v-show="purchaseId || this.$route.query['purchase_id']" class="text gr-15 gr-21@small gr-22@mini push-1 mb-0 mt-1">
          Die Abbuchung erfolgt durch Digistore24 GmbH, Deutschland.
        </p>
        <base-button @click="handleAfterPurchase" class="button mt-2">Jetzt loslegen</base-button>
    </div>
</template>

<script>
    export default {
        props: ['isAuthenticated', 'user', 'orderIsLoading'],
        inject: ['loadPage', 'setAuthMode', 'handleOrderProcess', 'setOrderLoading'],
        async mounted() {
          if (this.$route.query['purchase_id'] !== '[ORDER_ID]')
            this.setOrderLoading(true);

            await this.loadPage();

            let purchaseId = this.$route.query['purchase_id'] || false;

            if(purchaseId) {
                let signup = this.user? false : true;
                await this.handleOrderProcess(purchaseId, false, false, signup);
            } else {
                //freebie
                this.$router.replace({ name: 'home' });
                this.setOrderLoading(false);
            }
        },
        methods: {
            async handleAfterPurchase() {
                if(this.isAuthenticated) {
                    this.$router.replace({ name: 'portal'});
                } else {
                    this.setAuthMode('login');
                    this.$router.replace({ name: 'home' });
                }
            }
        }
    }
</script>

<style scoped>

</style>
