<template>

    <div class="coach" v-if="coach">

        <div class="coach-display">
            <div class="row">
                <div class="gr-24">

                    <div class="coach-display-data gr-16 gr-24@small">
                        <div class="coach-display-data-title">
                            <h1 class="push-1 push-0@small">{{ coach.firstName }} {{ coach.lastName }}</h1>
                        </div>

                        <div class="coach-display-data-info gr-11 gr-15@small gr-22@mobile push-1 push-0@small">
                            <h4 class="coach-display-data-info-qualification" v-if="coach.qualification">{{ coach.qualification }}</h4>
                            <p class="coach-display-data-info-bio" v-if="coach.bio">{{ coach.bio }}</p>
                        </div>
                    </div>

                    <div class="coach-display-image fixed gr-24 gr-15@small gr-21@mobile push-0 push-9@small push-3@mobile">
                        <img v-if="coach.profile.data" :src="require('../../strapi/public' + coach.profile.data.attributes.url)"
                             :alt="coach.firstName + ' ' + coach.lastName">
                    </div>
                </div>
            </div>
        </div>

        <div class="coach-links">

            <div class="row" v-for="(product, index) in courses" :key="index">
                <div class="gr-24">
                    <div class="coach-links-box gr-10 gr-15@small gr-20@mobile push-3 push-2@mobile">
                        <div class="coach-links-box-info">
                            <h3>{{ product.title }}</h3>
                            <p v-if="product.type === 'webinar'">
                                {{ product.time.from }}
                            </p>
                        </div>

                        <div class="coach-links-box-link">
                            <hr />
                            <router-link :to="{ name: 'course', params: { productType: product.type, slug: product.slug } }">
                                {{ product.type }} ➝
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="gr-24">
                    <div class="coach-links-box one-on-one gr-10 gr-15@small gr-20@mobile push-3 push-2@mobile">
                        <div class="coach-links-box-info">
                            <h3>One-on-One Coaching</h3>
                        </div>

                        <div class="coach-links-box-link">
                            <hr />
                            <a href="#contact" @click="setContactForm(true)">Jetzt Anfragen ➝</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <transition name="fade">
            <div class="coach-contact" v-if="displayContactForm" id="contact">

                <div class="basic-form row">

                    <div class="gr-24">

                        <form @submit.prevent="validateFormData"
                              class="form gr-14 gr-18@small gr-20@tablet gr-22@mobile push-5 push-3@small push-2@tablet push-1@mobile">

                            <table>

                                <thead>

                                <tr>
                                    <td></td>
                                    <td>
                                        <h3>Nachricht an Coach {{ coach.firstName }}  {{ coach.lastName }}</h3>
                                    </td>
                                </tr>

                                </thead>

                                <tbody>

                                <template v-if="!user || !isAuthenticated">

                                    <tr>
                                        <th>
                                            <label for="first-name">Vorname</label>
                                        </th>
                                        <td>
                                            <input type="text" id="first-name" name="firstName"
                                                   :value="contactData.firstName" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="!contactDataValidity.firstName">
                                            Bitte gib einen gültigen Vornamen an.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="last-name">Nachname</label>
                                        </th>
                                        <td>
                                            <input type="text" id="last-name" name="lastName"
                                                   :value="contactData.lastName" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="!contactDataValidity.lastName">
                                            Bitte gib einen gültigen Nachnamen an.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="email">E-Mail</label>
                                        </th>
                                        <td>
                                            <input type="text" id="email" name="email"
                                                   :value="contactData.email" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="!contactDataValidity.email">
                                            Bitte gib eine gültige Mailadresse an.
                                        </td>
                                    </tr>


                                </template>

                                <tr>
                                    <th>
                                        <label for="phone">Telefon</label>
                                    </th>
                                    <td>
                                        <input type="text" id="phone" name="phone" placeholder="z.B. +4366012345678"
                                               :value="contactData.phone" @input="setFormData">
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td class="error" v-if="!contactDataValidity.phone">
                                        Bitte gib eine gültige Telefonnummer inkl. Vorwahl und ohne Leerzeichen an (z.B. +4366012345678).
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        <label for="message">Nachricht</label><br>
                                    </th>
                                    <td>
                                        <textarea id="message" name="message" rows="10"
                                                  :value="contactData.message" @input="setFormData">
                                        </textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td class="error" v-if="contactDataValidity.message == false">
                                        Deine Nachricht muss mind. 10 Wörter enthalten.
                                    </td>
                                </tr>

                                <tr class="checkbox-row">
                                    <td></td>
                                    <td>
                                        <div class="checkbox">
                                            <input type="checkbox" id="privacy" name="privacy" v-model="contactData.privacy">
                                            <label :class="{ 'error': contactDataValidity.privacy === false}">
                                                Ich bin mit dem <a @click="openPrivacyPage">Datenschutz</a> einverstanden.
                                            </label>
                                        </div>
                                    </td>
                                </tr>

                                </tbody>

                                <tfoot>

                                <tr>
                                    <td colspan="2">
                                        <button type="submit" class="order-button button">SENDEN</button>
                                    </td>
                                </tr>

                                </tfoot>

                            </table>

                        </form>

                    </div>

                </div>

            </div>

        </transition>

        <div class="coach-products">

            <template v-for="(product, index) in this.courses" :key="index">

                <div class="product">
                    <product-display page="coachPage" size="regular" :product="product"
                                     :buttonText="product.freebie? 'weitere Infos' : product.price + ' Euro'"
                                     :videoIsLoading="videoIsLoading" :videoIsPlaying="videoIsPlaying">
                    </product-display>
                </div>

            </template>

        </div>
    </div>
</template>

<script>

    import ProductDisplay from '../components/products/ProductDisplay';

    export default {
        components: {
            ProductDisplay,
        },
        props: ['coachSlug', 'coaches', 'products', 'user', 'isAuthenticated', 'videoIsLoading', 'videoIsPlaying'],
        inject: ['setMetaData', 'submitCoachContactForm'],
        data() {
            return {
                displayContactForm: false,
                contactData: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                },
                contactDataValidity: {
                    firstName: true,
                    lastName: true,
                    email: true,
                    phone: true,
                    message: true,
                },
            }
        },
        watch: {
            metaData(curVal) {
                if(curVal) {
                    this.setMetaData(curVal);
                }
            },
            user(curVal) {
               this.contactData.phone = this.contactData.phone || curVal.phone || '';
            },
        },
        created() {
            if(this.metaData) {
                this.setMetaData(this.metaData);
            }
        },
        mounted() {
            this.contactData.phone = this.user && this.user.phone? this.user.phone : '';
        },
        methods: {
            async openPrivacyPage() {
                let link = this.$router.resolve({ name: 'privacy' });
                window.open(link.href, '_blank');
            },
            setContactForm(option) {
                this.displayContactForm = option;
            },
            async setFormData(event) {
                this.contactData[event.target.name] = event.target.value;
            },
            async validateFormData() {

                if(this.user && this.isAuthenticated) {
                    this.contactData.firstName = this.user.firstName;
                    this.contactData.lastName = this.user.lastName;
                    this.contactData.email = this.user.email;
                }

                if(this.contactData.firstName.length < 2) {
                    this.contactDataValidity.firstName = false;
                } else {
                    this.contactDataValidity.firstName = true;
                }

                if(this.contactData.lastName.length < 2) {
                    this.contactDataValidity.lastName = false;
                } else {
                    this.contactDataValidity.lastName = true;
                }

                if(!/\S+@\S+\.\S+/.test(this.contactData.email)) {
                    this.contactDataValidity.email = false;
                } else {
                    this.contactDataValidity.email = true;
                }

                if(!/^\+[1-9]{1}[0-9]{8,20}$/.test(this.contactData.phone)) {
                    this.contactDataValidity.phone = false;
                } else {
                    this.contactDataValidity.phone = true;
                }

                if(this.contactData.message.split(/\s+/).length < 10) {
                    this.contactDataValidity.message = false;
                } else {
                    this.contactDataValidity.message = true;
                }

                if(this.contactDataValidity.firstName && this.contactDataValidity.lastName &&
                    this.contactDataValidity.email && this.contactDataValidity.phone && this.contactDataValidity.message) {

                    if (!this.contactData.privacy) {
                        this.contactDataValidity.privacy = false;
                    } else {
                        this.contactDataValidity.privacy = true;
                        this.submit();
                    }
                }
            },
            async submit() {
                await this.submitCoachContactForm(this.coach.userId, this.contactData);

                this.displayContactForm = false;

                let phone = this.contactData.phone;
                this.contactData = {};

                if(this.user && this.isAuthenticated) {
                    this.contactData.phone = phone;
                }

                window.scrollTo(0, 0);
            },
        },
        computed: {
            metaData() {
                let metaData = false;

                if(this.coach) {
                    metaData = {
                        title: 'Coach ' + this.coach.firstName + ' ' + this.coach.lastName + ' * Starkes Mindset',
                        ogTitle: 'Coach ' + this.coach.firstName + ' ' + this.coach.lastName + ' * Starkes Mindset',
                        description: this.coach.qualification,
                        ogDescription: this.coach.qualification,
                        ogImage: 'https://starkes-mindset.com/img/og-starkes-mindset.jpg'
                    };
                }

                return metaData;
            },
            coach() {
                return Object.values(this.coaches).filter(coach => coach.coachSlug === this.coachSlug)[0];
            },
            courses() {
                let courses = [];
                for (let i in this.products.all) {
                    const product = this.products.all[i];
                    for(let j in this.coach.courses.data) {
                        const course = this.coach.courses.data[j].attributes;
                        if(product['product_id'] == course['product_id']) {
                            courses.push(this.products.all[i]);
                        }

                    }
                }

                return courses;
            }
        },
    }
</script>

<style scoped>
</style>
