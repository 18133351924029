import { createStore } from 'vuex';
import axios from 'axios';
import { REST_API_URL } from '../data/links.js';

import auth from './modules/auth/index.js';
import newsletter from './modules/newsletter/index.js';
import products from './modules/products/index.js';
import users from './modules/users/index.js';

const store = createStore({
    modules: {
        auth,
        newsletter,
        products,
        users,
    },
    actions: {
        async createLog(_, log) {
            await axios.post(REST_API_URL + '/logs', {
                data: {
                    message: log
                }
            });
        }
    }
})

export default store;
