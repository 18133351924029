<template>

    <div v-if="module">

        <base-button v-if="!displayModules" @click="backToModules" class="button mt-1">
          <img src="../../images/back-arrow.svg" width="24" height="24"/>
        </base-button>

        <transition name="fade">
            <router-view :authData="authData" :user="user" :module="module" :training="training" @set-active-unit="setActiveUnit" class="gr-24"></router-view>
        </transition>

        <div class="chapter gr-24 mb-1">

            <h3 class="gr-24">Kapitel {{ module.number }}</h3>

            <p class="gr-24" v-if="module.introduction">{{ module.introduction }}</p>

            <div v-for="(unit, index) in module.units" :key="index" class="gr-24">
                <div class="row" :class="{ active: unit.slug === activeUnit}">

<!--                    <router-link :to="{ name: 'unit', params: { 'unitSlug': unit.slug } }" class="gr-4">-->
<!--                        <img :src="cmsUrl + '/public' + unit.productImage.url" :alt="unit.title" v-if="unit.productImage">-->
<!--                    </router-link>-->

                    <div class="gr-18 gr-20@mobile">
                        <router-link :to="{ name: 'unit', params: { 'unitSlug': unit.slug } }">
                            <h4>
                                {{ unit.title }}
                            </h4>
                        </router-link>

                        <p v-if="unit.description">
                            {{ unit.description }}
                        </p>
                    </div>

                    <div class="gr-2">
                        <input id="completed" type="checkbox" :checked="user.completed && user.completed[training.slug] && user.completed[training.slug][module.slug] && user.completed[training.slug][module.slug][unit.slug]? user.completed[training.slug][module.slug][unit.slug] : false" @change="toggleCompletionState($event, unit)">
                    </div>

                </div>
            </div>

            <div class="notes gr-24">
                <h2 class="mb-1">Notizen zum Kurs:</h2>
                <form @submit.prevent="validateNotes">
                    <textarea class="gr-14 gr-22@mobile mb-1"
                        :value="notes[module.slug]" @input="editNotes" :disabled="!notesEditionMode[this.module.slug]"></textarea>
                    <div v-if="!notesValidity" class="error">Du hast noch keine Notiz erstellt.</div>
                  <br/>
                    <template v-if="notesEditionMode[this.module.slug]">

                        <base-button type="submit" color="green" class="button">Speichern</base-button>
                        <base-button type="button" color="red" class="button" @click="cancelNotes" v-if="notes[module.slug]">Abbrechen</base-button>

                    </template>

                    <template v-else>
                        <base-button type="button" class="button" @click="toggleNotesEdition(true)">Bearbeiten</base-button>
                        <base-button type="button" color="red" class="button" @click="eraseNotes">Löschen</base-button>
                    </template>

                </form>

            </div>

            <!--div class="links gr-24" v-if="module.references">
                <h4>Weiterführende Empfehlungen:</h4>

                <ul>
                    <li v-for="(reference, index) in module.references" :key="index">
                        <a :href="reference.url" target="_blank"><span class="icon"></span>{{ reference.title }}</a>
                    </li>
                </ul>
            </div-->

        </div>
      <div class="gr-24 mt-1">
        <base-button v-if="!displayModules" @click="backToModules" class="button mr-05">zurück zu den Modulen</base-button>
      </div>
    </div>
</template>

<script>
    export default {
        props: ['moduleSlug', 'authData', 'user', 'training'],
        inject: ['fetchUser'],
        provide() {
            return {
                setCompletionState: this.setCompletionState
            }
        },
        data() {
            return {
                notes: {},
                notesEditionMode: {},
                notesValidity: true,
                activeUnit: null,
                cmsUrl: process.env.VUE_APP_STRAPI_API_URL
            }
        },
        created() {
            this.notesValidity = true;
            this.notes[this.module.slug] = this.user.notes && this.user.notes[this.module.slug]? this.user.notes[this.module.slug] : '';

            if(!this.notes[this.module.slug]) {
                this.toggleNotesEdition(true);
            }
        },
        mounted() {
            if(this.user && this.user.lastPosition && this.user.lastPosition[this.training.slug] && this.user.lastPosition[this.training.slug].module.slug === this.module.slug) {
                this.$router.replace({ name: 'unit', params: { slug: this.training.slug, moduleSlug: this.user.lastPosition[this.training.slug].module.slug, unitSlug: this.user.lastPosition[this.training.slug].module.unit.slug } });
            } else {
                this.$router.replace({ name: 'unit', params: { slug: this.training.slug, moduleSlug: this.moduleSlug, unitSlug: this.module.units[0].slug } });
            }
        },
        methods: {
            backToModules() {
                this.$router.push({ name: 'purchase', params: { purchaseSlug : this.training.slug } });
            },
            async toggleCompletionState(event, unit) {
                this.setCompletionState(event.target.checked, unit);
            },
            async setCompletionState(completionState, unit) {

                let completed = this.user.completed || {};

                if(!completed[this.training.slug]) {
                    completed[this.training.slug] = {};
                }

                if(!completed[this.training.slug][this.module.slug]) {
                    completed[this.training.slug][this.module.slug] = {}
                }

                completed[this.training.slug][this.module.slug][unit.slug] = completionState;

                let moduleCompleted = true;

                for(let key in this.module.units) {
                    if(!completed[this.training.slug][this.module.slug][this.module.units[key].slug]) {
                        moduleCompleted = false;
                    }
                }

                completed[this.training.slug][this.module.slug]['done'] = moduleCompleted;

                const updateUnit = await this.$store.dispatch('users/updateModuleProgress', {
                    jwt: this.authData.jwt,
                    completed: completed
                });

                if(await updateUnit){
                    await this.fetchUser();
                }
            },
            setActiveUnit(unit) {
                this.activeUnit = unit;
            },
            toggleNotesEdition(option) {
                this.notesEditionMode[this.module.slug] = option;
            },
            editNotes(event) {
                this.notesValidity = true;
                this.notes[this.module.slug] = event.target.value;
            },
            validateNotes() {
                if(this.notes[this.module.slug] === '' || this.notes[this.module.slug] === null) {
                    this.notesValidity = false;
                } else {
                    this.notesValidity = true;
                    this.saveNotes();
                }
            },
            async saveNotes() {
                let notes = this.user.notes || {};
                notes[this.module.slug] = this.notes[this.module.slug];

                const saveNotes = await this.$store.dispatch('users/saveNotes', {
                    jwt: this.authData.jwt,
                    notes: this.notes
                });

                if(await saveNotes) {
                    await this.fetchUser();
                }

                if(this.notes[this.module.slug] === null) {
                    this.toggleNotesEdition(true);
                } else {
                    this.toggleNotesEdition(false);
                }
            },
            cancelNotes() {
                this.notes[this.module.slug] = this.user.notes && this.user.notes[this.module.slug]? this.user.notes[this.module.slug] : null;

                if(this.notes[this.module.slug] === null) {
                    this.notesEditionMode[this.module.slug] = true;
                } else {
                    this.notesEditionMode[this.module.slug] = false;
                }
            },
            eraseNotes() {
                this.notes[this.module.slug] = null;

                if(this.user.notes && this.user.notes[this.module.slug]) {
                    this.saveNotes();
                }
            }
        },
        computed: {
            modules() {
                return this.training.modules;
            },
            module() {
                let module;

                for(let key in this.modules) {
                    let targetModule = this.modules[key];
                    if(targetModule.slug === this.moduleSlug) {
                        module = targetModule;
                    }
                }

                return module;
            },
        }
    }
</script>


<style scoped>
</style>
