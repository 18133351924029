<template>
    <div class="purchase-page">

        <div class="row">

            <div class="gr-24">

<!--                <base-button @click="backToPortalOverview" class="button">-->
<!--                <base-button @click="backToPortalOverview" class="button push-1 push-2@mobile">-->
<!--                    zurück zu den Abonnements-->
<!--                </base-button>-->

                <transition name="fade">

                    <keep-alive :key="componentType">
                        <component :is="componentType" :authData="authData" :content="courseContent" :user="user"
                                   :coaches="product.coaches.data" :videoIsPlaying="videoIsPlaying" v-if="product">
                        </component>
                    </keep-alive>

                </transition>

            </div>

        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue';
    const Challenge = defineAsyncComponent(() => import('./Challenge.vue'));
    const Training = defineAsyncComponent(() => import('./Training.vue'));
    const Webinar = defineAsyncComponent(() => import('./Webinar.vue'));

    export default {
        props: ['purchaseSlug', 'authData', 'user', 'products', 'videoIsPlaying'],
        components: {
            Challenge,
            Training,
            Webinar,
        },
        provide() {
            return {
                fetchCourseContent: this.fetchCourseContent
            }
        },
        data() {
            return {
                componentType: false,
            }
        },
        watch: {
            async product(curVal) {
                if(curVal) {
                    await this.fetchCourseContent();
                    this.componentType = this.product.type;
                }
            }
        },
        async mounted() {
            if(this.product) {
                await this.fetchCourseContent();
                this.componentType = this.product.type;
            }
        },
        methods: {
            async fetchCourseContent() {
                await this.$store.dispatch('products/fetchCourseContent', {
                    jwt: this.authData.jwt,
                    slug: this.purchaseSlug,
                    productType: this.product.type,
                });
            },
            backToPortalOverview() {
                this.$router.push({ name: 'portalOverview' });
            },
        },
        computed: {
            product() {
                let product = false;

                    for (let key in this.products.all) {
                        let singleProduct = this.products.all[key];

                        if (singleProduct.slug == this.purchaseSlug) {
                            product = singleProduct;
                        }
                    }

                return product;
            },
            courseContent() {
                return this.$store.getters['products/getCourseContent'] || false;
            }
        }
    }
</script>


<style scoped>
</style>
