import axios from 'axios';
import { REST_API_URL } from '../../../data/links.js';

export default {
    namespaced: true,
    state() {
        return {
            errorMessage: null
        }
    },
    mutations: {
        setErrorMessage(state, error) {
            state.errorMessage = error;
        }
    },
    actions: {
        async addNewsletterSubscriber({dispatch}, payload) {
            const email = payload.email;

            try {
                const response = await axios.post(REST_API_URL + '/newsletters', {
                    data: {
                        email: email
                    }
                })

                const responseData = await response.data;

                if(response.ok == false) {
                    const error = 'Es ist ein Fehler aufgetreten, bitte versuche nochmal, dich anzumelden.';
                    dispatch('setErrorMessage', error);

                    const log = responseData.message || 'Error: User konnte nicht in die Newsletter-Liste aufgenommen werden.';
                    dispatch('createLog', log, { root : true });
                    return false;
                }

                return true;

            } catch(err) {
                const error = 'Du hast dich bereits für unseren Newsletter angemeldet.';
                dispatch('setErrorMessage', error);
            }
        },
        setErrorMessage({commit}, payload) {
            const error = payload;
            commit('setErrorMessage', error);
        },
        clearErrorMessage({commit}) {
            commit('setErrorMessage', null);
        },
    },
    getters: {
        getErrorMessage(state) {
            return state.errorMessage;
        }
    }
}
