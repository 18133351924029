<template>

    <template v-if="product.introductionVideo">

        <transition name="fade">

            <div v-if="!videoIsPlaying[product.slug]" class="product-video-button"
                 @click="toggleVideo(product.slug, $refs[product.slug])">
                <img src="../../images/play_button.png" alt="Play">
            </div>

        </transition>

        <transition name="fade">

            <div v-if="videoIsPlaying[product.slug]" class="product-video-button"
                 @click="toggleVideo(product.slug, $refs[product.slug])">
                <img src="../../images/pause_button.png" alt="Stop">
            </div>

        </transition>

    </template>

    <div class="product-display">
        <div class="row">
            <div class="gr-24">

                <template v-if="product.introductionVideo">

                    <div class="product-display-video gr-17 gr-20@small push-7 push-4@small"
                         :class="{ 'to-front': videoIsPlaying[product.slug], 'to-back': videoIsPlaying[product.slug] == false}">
                        <vimeo-player :ref="product.slug" :video-id="product.introductionVideo" controls=false
                                      @playing="setVideoLoading(false, product.slug)"
                                      @pause="setVideoLoading(false, product.slug)"
                                      @ended="toggleVideo(product.slug, $refs[product.slug])">
                        </vimeo-player>

                        <div class="loading-page" v-if="videoIsLoading[product.slug]">
                            <base-spinner class="spinner"></base-spinner>
                        </div>
                    </div>

                    <transition name="fade">

                        <div v-if="!videoIsPlaying[product.slug]" class="product-display-video-button-tablet"
                             @click="toggleVideo(product.slug, $refs[product.slug])">
                            <img src="../../images/play_button.png" alt="Play">
                        </div>

                    </transition>

                    <transition name="fade">

                        <div v-if="videoIsPlaying[product.slug]" class="product-display-video-button-tablet"
                             @click="toggleVideo(product.slug, $refs[product.slug])">
                            <img src="../../images/pause_button.png" alt="Stop">
                        </div>

                    </transition>

                </template>

                <div class="product-display-data gr-16 gr-24@small">
                    <div class="product-display-data-title">
                        <h1 class="push-1 push-0@small">{{ product.title }}</h1>
                    </div>

                    <template class="product-display-data-info gr-18 gr-15@small gr-22@mobile push-1 push-0@small">

                        <p>{{ product.specification }}</p>

                        <h2 v-for="(coach, index) in product.coaches.data" :key="index" class="product-display-data-info-coach">
                            mit <router-link :to="{ name: 'coach', params: { coachSlug: coach.attributes.coachSlug } }"
                                             class="coach">{{ coach.attributes.firstName + ' ' + coach.attributes.lastName }}
                                </router-link>
                        </h2>

                    </template>
                </div>
            </div>

        </div>
    </div>

    <div class="product-details">
        <div class="row">
            <div class="gr-24">
                <div class="product-details-container-main gr-15 gr-18@small gr-19@tablet gr-22@mobile push-3 push-2@small push-1@mobile">
                    <div class="product-details-container-main-content">
                        <div>
                            <h3 class="gr-15 gr-21small gr-22@mini push-1">{{ product.descriptionLabel }}</h3>
                            <p class="gr-15 gr-21@small gr-22@mini push-1">{{ product.descriptionText }}</p>
                        </div>

                        <template v-if="page === 'orderPage'">

                            <base-button v-if="isAuthenticated" @click="submitOrder"
                                         class="button product-details-container-main-content-button gr-13 gr-18@small
                                                gr-24@mobile gr-22@mini push-1 push-1@mobile"
                                         :disabled="purchased" :style="{ cursor: purchased? 'auto' : 'pointer'}">
                                {{ buttonText }}
                            </base-button>

                            <a v-else href="#signup" class="gr-13 gr-18@small gr-24@mobile gr-22@mini push-1 push-1@mobile">
                                <base-button class="button home-button product-details-container-main-content-button">
                                    {{ buttonText }}
                                </base-button>
                            </a>

                          <p v-show="!purchased" class="gr-15 gr-21@small gr-22@mini push-1 mb-0">{{ productPrice }}</p>

                        </template>

                        <!-- landing page or coach page -->
                        <base-button v-else @click="openProductPage(product.type, product.slug)"
                            class="product-details-container-main-content-button button gr-15 gr-21@small gr-22@mobile push-1 push-0@mobile">
                            {{ buttonText }}
                        </base-button>
                    </div>
                </div>

                <div class="product-details-container-side gr-8 gr-14@small gr-16@tablet gr-22@mobile push-8@small push-7@tablet push-1@mobile">
                    <div class="product-details-container-side-content gr-20 gr-22@small push-2 push-1@small">
                        <h3>Benefits</h3>
                        <ul>
                            <li v-for="(goal, index) in product.benefits" :key="index">
                                {{ goal }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        props: ['isAuthenticated', 'page', 'product', 'purchased', 'buttonText', 'productPrice', 'videoIsLoading', 'videoIsPlaying'],
        emits: ['submitOrder'],
        inject: ['setVideoLoading', 'toggleVideo'],
        data() {
            return {
            }
        },
        methods: {
            openProductPage(type, slug) {
                this.$router.push({ name: 'course', params: { productType: type, slug: slug }})
            },
            submitOrder() {
                this.$emit('submitOrder');
            }
        },
    }
</script>


<style scoped>
</style>
