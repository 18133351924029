<template>
    <div>
        <div class="product" v-if="product">

          <product-display page="orderPage" :product="product" :purchased="purchased"
                           :buttonText="purchased? 'abonniert' : (product.freebie? 'kostenlose Teilnahme' : 'Teilnehmen')"
                           :productPrice="product.freebie ? '' : 'Preis: '+ (!Number.isInteger(product.price)? parseFloat(product.price).toFixed(2) : product.price) + ' Euro'"
                           :videoIsLoading="videoIsLoading" :videoIsPlaying="videoIsPlaying"
                           :isAuthenticated="isAuthenticated" @submit-order="submit">
          </product-display>

            <!--div class="product-addition" v-if="product.orderAddition">
                <div class="row">
                    <div class="gr-24">

                        <div class="product-addition-container gr-17 push-4 gr-20@mobile push-2@mobile">

                            <h3>Infos</h3>
                            <div class="product-addition-container-content">
                                {{ product.orderAddition }}
                            </div>

                        </div>

                    </div>
                </div>
            </div-->

            <div class="product-testimonials" v-if="product.testimonials && product.testimonials.length">
                <div class="row">
                    <div class="gr-24">

                        <h2 class="product-testimonials-headline gr-16@tablet gr-22@mobile push-6 push-6@tablet push-1@mobile">
                            Feedback von Teilnehmer:innen</h2>
                        <div class="product-testimonials-container gr-21 gr-18@tablet gr-22@mobile push-2 push-3@tablet push-1@mobile">

                            <div v-for="(testimonial, index) in product.testimonials" :key="index"
                                 class="product-testimonials-container-testimonial gr-8 gr-24@tablet">

                                <p class="product-testimonials-container-testimonial-quote">
                                    {{ testimonial.quote }}
                                </p>
                                <h5 class="product-testimonials-container-testimonial-signature">
                                    {{ testimonial.signature }}
                                </h5>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="product-challenge" v-if="product.type === 'challenge'">
                <div class="row">
                    <div class="gr-24">
                        <div class="row">
                            <div class="gr-24">
                                <h2 class="product-challenge-headline gr-9 gr-19@tablet gr-22@mobile push-6 push-2@tablet push-1@mobile">
                                    Lerne von Experten, wie du ein starkes Mindset für deine persönlichen Ziele erschaffen kannst.</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="gr-24">
                                <div class="product-challenge-instructions product-challenge-instructions-top gr-9 gr-18@tablet gr-20@mobile push-6 push-3@tablet push-2@mobile">
                                    <h5 class="product-challenge-instructions-subheadline">So einfach geht’s</h5>
                                    <p class="product-challenge-instructions-text">
                                        Nach der Anmeldung erhältst du ein kurzes Video mit den ersten Schritten zur App und dem Zugang zur Challenge.
                                        In wenigen Minuten bist du startklar.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="gr-24">
                                <div class="product-challenge-steps gr-18 gr-20@small gr-20@mobile push-3 push-2@small push-2@mobile">
                                    <div class="product-challenge-steps-step product-challenge-steps-step-one">
                                        <span class="product-challenge-steps-step-number">1</span>Anmelden</div>

                                    <div class="product-challenge-steps-step product-challenge-steps-step-two">
                                        <span class="product-challenge-steps-step-number">2</span>App installieren</div>

                                    <div class="product-challenge-steps-step product-challenge-steps-step-three">
                                        <span class="product-challenge-steps-step-number">3</span>Challenge starten</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="gr-24">
                                <div class="product-challenge-instructions product-challenge-instructions-bottom gr-9 gr-18@tablet gr-20@mobile push-6 push-3@tablet push-2@mobile">
                                    <h5 class="product-challenge-instructions-subheadline">Was du dafür brauchst</h5>
                                    <div class="product-challenge-instructions-text gr-24">
                                        <div class="product-challenge-instructions-text-icon product-challenge-instructions-text-icon-one"></div>
                                        <p>Alle Inhalte und Aufgaben der Challenge kannst du über dein Smartphone per App aufrufen.</p>
                                    </div>
                                    <div class="product-challenge-instructions-text gr-24">
                                        <div class="product-challenge-instructions-text-icon product-challenge-instructions-text-icon-two"></div>
                                        <p>Für die Aufgaben wirst du {{ product.duration? product.duration : 5 }} Minuten am Tag benötigen. Darüber hinaus steht dir das Üben frei.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="gr-24">
                                <a href="#signup" class="gr-4 push-6 gr-18@tablet gr-24@mobile push-3@tablet push-13@mobile">
                                    <button class="product-challenge-button button">Let's go!</button>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="product-challenge" v-else-if="product.type === 'training'">
            <div class="row">
              <div class="gr-24">
                <div class="row">
                  <div class="gr-24">
                    <h2 class="product-challenge-headline gr-9 gr-19@tablet gr-22@mobile push-6 push-2@tablet push-1@mobile">
                      Lerne von Experten, wie du ein starkes Mindset für deine persönlichen Ziele erschaffen kannst.</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="gr-24">
                    <div class="product-challenge-instructions product-challenge-instructions-top gr-9 gr-18@tablet gr-20@mobile push-6 push-3@tablet push-2@mobile">
                      <h5 class="product-challenge-instructions-subheadline">So einfach geht’s</h5>
                      <p class="product-challenge-instructions-text">
                        Nach dem Kauf erhältst du deinen Online-Zugang zum Training mit allen Ressourcen.
                        In wenigen Minuten bist du startklar.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="gr-24">
                    <div class="product-challenge-steps gr-18 gr-20@small gr-20@mobile push-3 push-2@small push-2@mobile">
                      <div class="product-challenge-steps-step product-challenge-steps-step-one">
                        <span class="product-challenge-steps-step-number">1</span>Kaufen</div>

                      <div class="product-challenge-steps-step product-challenge-steps-step-two">
                        <span class="product-challenge-steps-step-number">2</span>im Portal Anmelden</div>

                      <div class="product-challenge-steps-step product-challenge-steps-step-three">
                        <span class="product-challenge-steps-step-number">3</span>Training starten</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="gr-24">
                    <div class="product-challenge-instructions product-challenge-instructions-bottom gr-9 gr-18@tablet gr-20@mobile push-6 push-3@tablet push-2@mobile">
                      <h5 class="product-challenge-instructions-subheadline">Was du dafür brauchst</h5>
                      <div class="product-challenge-instructions-text gr-24">
                        <div class="product-challenge-instructions-text-icon product-challenge-instructions-text-icon-one"></div>
                        <p>Alle Inhalte und Aufgaben des Trainings kannst du über deine Desktop- und Mobilgeräte im Browser aufrufen.</p>
                      </div>
                      <div class="product-challenge-instructions-text gr-24">
                        <div class="product-challenge-instructions-text-icon product-challenge-instructions-text-icon-two"></div>
                        <p>Die Aufgaben kannst du in deinem persönlichem Tempo bearbeiten und jederzeit wiederholen.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="gr-24">
                    <a href="#signup" class="gr-4 push-6 gr-18@tablet gr-24@mobile push-3@tablet push-13@mobile">
                      <button class="product-challenge-button button">Let's go!</button>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>

            <transition name="fade">

                <div class="product-signup" id="signup" v-if="!purchased && !isAuthenticated">
                    <div class="basic-form row">
                        <div class="gr-24">
                            <form @submit.prevent="validateFormData"
                                  class="form gr-14 gr-18@small gr-20@tablet gr-22@mobile push-5 push-3@small push-2@tablet push-1@mobile">

                                <table>

                                    <thead>

                                    <tr>
                                        <td></td>
                                        <td>
                                            <h3>{{ product.type === 'challenge'? 'Zur Challenge' : (product.type === 'training'? 'Zum Training' : 'Zum Webinar') }} anmelden:</h3>
                                        </td>
                                    </tr>

                                    </thead>

                                    <tbody>

                                    <tr>
                                        <th>
                                            <label for="first-name">Vorname</label>
                                        </th>
                                        <td>
                                            <input type="text" id="first-name" name="firstName"
                                                   :value="userData.firstName" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="!userDataValidity.firstName">Bitte gib einen gültigen Vornamen an.</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="last-name">Nachname</label>
                                        </th>
                                        <td>
                                            <input type="text" id="last-name" name="lastName"
                                                   :value="userData.lastName" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="!userDataValidity.lastName">Bitte gib einen gültigen Nachnamen an.</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="email">E-Mail</label>
                                        </th>
                                        <td>
                                            <input type="text" id="email" name="email"
                                                   :value="userData.email" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="!userDataValidity.email">Bitte gib eine gültige Mailadresse an.</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="phone">Telefon</label>
                                        </th>
                                        <td>
                                            <input type="text" id="phone" name="phone" :value="userData.phone"
                                                   placeholder="z.B. +4366012345678" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="!userDataValidity.phone">
                                            Bitte gib eine gültige Telefonnummer inkl. Vorwahl und ohne Leerzeichen an (z.B. +4366012345678).
                                        </td>
                                    </tr>

                                    <tr class="checkbox-row">
                                        <td></td>
                                        <td>
                                            <div class="checkbox">
                                                <input type="checkbox" id="privacy" name="privacy" v-model="userData.privacy">
                                                <label :class="{ 'error': !userDataValidity.privacy }">
                                                    Ich bin mit dem <a @click="openPrivacyPage">Datenschutz</a> einverstanden.
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="checkbox-row">
                                        <td></td>
                                        <td>
                                            <div class="checkbox">
                                                <input type="checkbox" id="newsletter" name="newsletter"
                                                       v-model="userData.newsletter">
                                                <label for="privacy">Ich möchte euren Newsletter abonnieren.</label>
                                            </div>
                                        </td>
                                    </tr>

                                    </tbody>

                                    <tfoot>

                                    <tr>
                                        <td colspan="2">
                                            <button type="submit" class="order-button button">TEILNEHMEN & STARTEN</button>
                                        </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2" class="note">
                                        Sie können das Produkt über Digistore24 kaufen und werden über obigen Button zur Digistore24-Verkaufsseite weitergleitet.
                                      </td>
                                    </tr>

                                    </tfoot>

                                </table>

                            </form>
                        </div>
                    </div>
                </div>
            </transition>

            <div class="product-podcasts" v-if="product.podcasts && product.podcasts.length">
                <div class="row">
                    <div class="gr-24">
                        <div class="product-podcasts-container gr-21 gr-22@tablet gr-24@mobile push-2 push-1@tablet push-0@mobile">

                            <h2 class="gr-22@mobile push-1 push-0@tablet push-1@mobile">
                                Podcasts zum Thema{{ product.topic? ' ' + product.topic : '' }}
                            </h2>

                            <div class="product-podcasts-container-videos gr-24@mobile">
                                <div class="row">
                                    <div v-for="(podcast, index) in this.product.podcasts" :key="index"
                                         class="stream gr-8 gr-22@mobile push-1@mobile">
                                        <iframe :src="'https://open.spotify.com/embed/episode/' + podcast +  '?utm_source=generator&theme=0'"
                                                width="100%" height="232" frameBorder="0" allowfullscreen=""
                                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
                                        </iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['slug', 'products', 'purchases', 'isAuthenticated', 'user', 'contact', 'subscriptionCompleted', 'videoIsLoading', 'videoIsPlaying'],
        inject: ['setMetaData', 'loadPage', 'setLoading', 'setVideoLoading', 'toggleVideo', 'setNotice', 'checkUserAuthenticationStatus', 'checkUserPurchases', 'submitNewsletter', 'fetchContactPurchases'],
        data() {
            return {
                userData: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                },
                userDataValidity: {
                    firstName: true,
                    lastName: true,
                    email: true,
                    phone: true,
                    message: true,
                },
            }
        },
        watch: {
            metaData(curVal) {
                if(curVal) {
                    this.setMetaData(curVal);
                }
            },
            products(curVal) {
                if(curVal) {
                    if(!curVal.all.filter(product => product.slug === this.slug).length) {
                        return this.$router.replace( { name: 'home' } );
                    }
                }
            },
        },
        created() {
            if(this.metaData) {
                this.setMetaData(this.metaData);
            }
        },
        methods: {
            openPrivacyPage() {
                let link = this.$router.resolve({ name: 'privacy' });
                window.open(link.href, '_blank');
            },
            setFormData(event) {
                this.userData[event.target.name] = event.target.value;
            },
            validateFormData() {
                if(this.userData.firstName.length < 2) {
                    this.userDataValidity.firstName = false;
                } else {
                    this.userDataValidity.firstName = true;
                }

                if(this.userData.lastName.length < 2) {
                    this.userDataValidity.lastName = false;
                } else {
                    this.userDataValidity.lastName = true;
                }

                if(!/\S+@\S+\.\S+/.test(this.userData.email)) {
                    this.userDataValidity.email = false;
                } else {
                    this.userDataValidity.email = true;
                }

                if(!/^\+[1-9]{1}[0-9]{8,20}$/.test(this.userData.phone)) {
                    this.userDataValidity.phone = false;
                } else {
                    this.userDataValidity.phone = true;
                }

                if(this.userDataValidity.firstName && this.userDataValidity.lastName
                    && this.userDataValidity.email && this.userDataValidity.phone) {

                    if(!this.userData.privacy) {
                        this.userDataValidity.privacy = false;
                    } else {
                        this.userDataValidity.privacy = true;
                        this.submit();
                    }
                }
            },
            async submit() {
                const user = this.user || this.userData;
                const newsletter = this.userData.newsletter;

                this.setLoading(true);

                if(!this.isAuthenticated) {
                    await this.checkUserAuthenticationStatus(user, this.product, newsletter);

                } else {
                    await this.checkUserPurchases(this.product);
                }
            },
        },
        computed: {
            product() {
                let product;

                if(this.products) {
                    product = Object.values(this.products.all).filter(product => product.slug === this.slug)[0];

                }

                return product;
            },
            purchased() {
                let purchased = false;

                if(this.contact && !this.purchases) {
                    this.fetchContactPurchases(this.contact.id);
                }

                if(this.purchases.length) {
                    purchased = !!Object.values(this.purchases).filter(purchase => purchase.slug === this.slug)[0];

                }

                return purchased;
            },
            metaData() {
                let metaData = false;

                if(this.product) {
                    metaData = {
                        title: this.product.title + ' * Starkes Mindset',
                        ogTitle: this.product.title + ' * Starkes Mindset',
                        description: this.product.specification,
                        ogDescription: this.product.specification,
                        ogImage: 'https://starkes-mindset.com/img/og-' + this.product.slug + '.jpg',
                    };
                }

                return metaData;
            }
        }
    }
</script>

<style scoped>
</style>
