<template>

    <div class="portal-overview">
        <h1 v-if="contact">Meine Produkte</h1>
        <div class="portal-overview-products">

            <div class="portal-overview-products-container row" v-if="purchases">

                <div v-for="(purchase, index) in purchases" :key="index"
                     class="portal-overview-products-container-product gr-24">

<!--                    <img :src="cmsUrl + '/public' + purchase.image.url" :title="purchase.title"-->
<!--                         class="gr-8" v-if="purchase.image">-->

                    <h2 class="gr-10 gr-24@mobile mb-1">{{ purchase.title }}</h2>

                    <base-button
                        v-if="purchase.type === 'training'"
                        :to="{ name: 'purchase', params: { purchaseSlug: purchase.slug } }" class="button gr-6" link>
                        Zum Kurs
                    </base-button>

                </div>

            </div>

            <div v-else>
              <h1>Starkes Mindset</h1>
              Du besitzt noch keine Abonnements
            </div>

        </div>
    </div>

</template>

<script>

    export default {
        props: ['contact', 'purchases'],
        inject: ['fetchContactPurchases'],
        data() {
          return {
            cmsUrl: process.env.VUE_APP_STRAPI_API_URL
          }
        },
        watch: {
            async contact(curVal) {
                if(curVal) {
                    this.fetchContactPurchases(curVal.id);
                }
            }
        },
        async mounted() {
            if(this.contact) {
                this.fetchContactPurchases(this.contact.id);
            }
        },
    }

</script>


<style scoped>
</style>
